import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@mui/icons-material/Close";
import InputMask from "react-input-mask";
import MenuItem from "@material-ui/core/MenuItem";
import * as S from "../../../containers/Report/styles";
import { translateStatusToFront } from "../../utils";

function Filter(props) {
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    moment.locale("pt");
    moment.updateLocale("pt", {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    });
    moment.updateLocale("pt", {
      weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    });
  });

  const handleFocusChange = (focus) => {
    if (focus === null && (props.value.initialDate || props.value.finalDate)) {
      props.onBlur();
    }
    setFocus(focus);
  };

  const getMomentDate = (date, nullCase) => {
    if (date == null) return nullCase;
    else return moment(date);
  };

  if (props.filter_type.type === "masked_string") {
    return (
      <>
        <S.FilterContainer>
          <S.FilterContainerDescription>
            {props.filter.description}
          </S.FilterContainerDescription>
          <InputMask
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.onBlur();
              }
            }}
            disabled={props.filter.disabled}
            mask={props.filter_type.maskType}
            maskPlaceholder=""
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={props.value}
          >
            {() => (
              <TextField
                style={{
                  fontFamily: "Plus Jakarta Sans",
                  margin: "4px 5px",
                  fontSize: "14px",
                }}
                size="small"
                fullWidth
              />
            )}
          </InputMask>

          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </S.FilterContainer>
      </>
    );
  } else if (props.filter_type.type === "numeric") {
    return (
      <>
        <S.FilterContainer>
          <S.FilterContainerDescription>
            {props.filter.description}
          </S.FilterContainerDescription>
          <TextField
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.onBlur();
              }
            }}
            disabled={props.filter.disabled}
            onBlur={props.onBlur}
            onChange={props.onChange}
            style={{
              width: "100%",
              fontFamily: "Plus Jakarta Sans",
              margin: "4px 5px",
              fontSize: "12px",
            }}
            size="small"
            value={props.value}
            type="number"
            pattern="[0-9]*"
            fullWidth
          />
          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </S.FilterContainer>
      </>
    );
  } else if (props.filter_type.type === "enum") {
    return (
      <>
        <S.FilterContainer>
          <S.FilterContainerDescription>
            {props.filter.description}
          </S.FilterContainerDescription>
          <TextField
            disabled={props.filter.disabled}
            style={{
              fontFamily: "Plus Jakarta Sans",
              margin: "4px 5px",
              fontSize: "12px",
              textAlign: "center",
            }}
            onBlur={props.onBlur}
            onChange={props.onChange}
            size="small"
            fullWidth
            value={props.value}
            select
          >
            {props.filter_type.enumList.map((enumList, index) => (
              <MenuItem key={index} value={enumList[1]}>
                <em
                  style={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                  }}
                >
                  {translateStatusToFront(enumList[0])}
                </em>
              </MenuItem>
            ))}
          </TextField>
          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </S.FilterContainer>
      </>
    );
  } else if (props.filter_type.type === "date") {
    return (
      <>
        <S.FilterContainer>
          <S.FilterContainerDescription>Data</S.FilterContainerDescription>
          <DateRangePicker
            style={{ backgroundColor: 'lightblue', color: 'red' }}
            disabled={props.filter.disabled}
            startDatePlaceholderText={"Início"}
            endDatePlaceholderText={"Final"}
            startDate={getMomentDate(props.value.initialDate, null)}
            startDateId="initial_date_id"
            endDate={getMomentDate(props.value.finalDate, null)}
            endDateId="final_date_id"
            onDatesChange={({ startDate, endDate }) =>
              props.onChange({
                initial_date_value: startDate,
                final_date_value: endDate,
              })
            }
            dayPickerProps={{
              style: {
                backgroundColor: 'lightblue', // Cor de fundo do calendário
                color: 'red', // Cor do texto do calendário
              }
            }}
            focusedInput={focus}
            onFocusChange={(focus) => handleFocusChange(focus)}
            showDefaultInputIcon={false}
            isOutsideRange={(day) => moment().diff(day.startOf("day")) < 0}
            minimumNights={0}
            small={true}
            displayFormat={() => "DD/MM"}
            readOnly={true}
          />
          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </S.FilterContainer>
      </>
    );
  } else {
    return (
      <>
        <S.FilterContainer>
          <S.FilterContainerDescription>
            {props.filter.description}
          </S.FilterContainerDescription>
          <TextField
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.onBlur();
              }
            }}
            style={{
              width: "100%",
              fontFamily: "Plus Jakarta Sans",
              margin: "4px 5px",
              fontSize: "12px",
            }}
            disabled={props.filter.disabled}
            onBlur={props.onBlur}
            onChange={props.onChange}
            className="filterTextbox"
            size="small"
            value={props.value}
            fullWidth
          />
          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </S.FilterContainer>
      </>
    );
  }
}

export default Filter;
