
export const NoAccessPage = (props) => {
    
    return (
        <div className="page-container">
            <p className="message__container message__container--centered">
                <span className="message__text">
                    Você não possui acesso.
                </span>
                <span className="message__text">
                    Contate suporte.caas@qitech.com.br
                </span>
            </p>
        </div>
    )
}