import React, { useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from '@mui/material';
import { LabelInput } from '../../../components/LabelInput';
import { formatPhoneWithoutIntCode, isPhoneWithoutIntCode, isEmail, justNumbers } from '../../../utils/utils';
import BaseButton from '../../../components/BaseButton';
import axios from 'axios';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear'

function SignerBatchDataDialog(props) {

    const {
        open,
        setOpen,
        companyKey,
        signerBatchKey,
        setSignerBatchKey,
        signerBatchName,
        setSignerBatchName,
        values,
        setValues,
        fields
    } = props

    const [errors, setErrors] = useState({
        phone: "",
        email: ""
    });

    const errorMessages = {
        phone: "Telefone deve conter 11 dígitos.",
        email: "Email inválido."
    }

    const validateInput = {
        phone: (value)=> isPhoneWithoutIntCode(value),
        email: (value)=> isEmail(value)
    }

    const handleValueChange = (id, value) => {
        setValues({
            ...values,
            [id]: value
        })
        setErrors({
            ...errors,
            [id]: ""
        });
    }

    const handleBlur = (id, value) => {
        const error = !validateInput[id](value);
        setErrors({
            ...errors,
            [id]: error
        });
    };
    
    const [requestLoading, setRequestLoading] = useState(false)
    const [requestError, setRequestError] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(false)

    const statesToInitial = useCallback(() => {
        setSignerBatchKey(undefined)
        setSignerBatchName(undefined)
        setValues(undefined)
        setErrors({
            phone: "",
            email: ""
        })
        setRequestSuccess(false)
        setRequestError(false)
        setRequestLoading(false)
    },[setSignerBatchKey, setSignerBatchName, setValues, setErrors, setRequestSuccess, setRequestError, setRequestLoading])

    const onCloseDialog = useCallback(() => {
        statesToInitial()
        setOpen(false)
    },[statesToInitial, setOpen])

    const updateSignerData = useCallback(()=>{
        let data = {}
        if (fields.phone) {
            const phoneNumber = justNumbers(values.phone)
            data["phone"] = {
                "international_dial_code": "55",
                "area_code": phoneNumber.substring(0,2),
                "number": phoneNumber.substring(2,13)
            }
        }
        if (fields.email) {
            data["email"] = (values.email).trim()
        }
        setRequestLoading(true)
        const timer = setTimeout(() => {
            axios.patch(`/dash/sign/company/${companyKey}/signer_batch/${signerBatchKey}/signer_batch_data`, data)
                .then(() => {
                    setRequestSuccess(true)
                    const successMessageTimer = setTimeout(() => {
                        onCloseDialog()
                    }, 3000);
                    return () => {
                        clearTimeout(successMessageTimer)
                    }
                })
                .catch(() => {
                    setRequestError(true)
                    const errorMessageTimer = setTimeout(() => {
                        setRequestError(false)
                    }, 10000);
                    return () => {
                        clearTimeout(errorMessageTimer)
                    }
                })
                .finally(() => {
                    setRequestLoading(false)
                })
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    },[fields.email, fields.phone, onCloseDialog, signerBatchKey, companyKey, values.email, values.phone])

    const getButtonStyle = () => {
        const style = { 
            disabled: {
                icon: null,
                label: "ALTERAR",
                isDisabled: true,
                className: "btn--white"
            },
            active: {
                icon: null,
                label: "ALTERAR",
                isDisabled: false,
                className: "btn--white"
            },
            success: {
                icon: <Check style={{height:"12px", margin: "auto", scale:"1.5"}}/>,
                label: "",
                isDisabled: true,
                className: "btn--green"
            },
            error: {
                icon: <Clear style={{height:"12px", margin: "auto", scale:"1.5"}}/>,
                label: "",
                isDisabled: true,
                className: "btn--red"
            }
        }
        if (!validateInput.email(values.email)||!validateInput.phone(values.phone)) {
            return style.disabled
        } else if (requestSuccess) {
            return style.success
        } else if (requestError) {
            return style.error
        } else {
            return style.active
        }
    }

    return (
        <Dialog
            open={open}
            onClose={()=>onCloseDialog()}
            fullWidth
            maxWidth="xs"
            >
            <div className="dialog__column-container">
                <header>
                    <span>{`Edição dos Dados de Envio do(a) ${signerBatchName}`}</span>
                    <IconButton 
                        onClick={()=>onCloseDialog()}
                    >
                        <CloseIcon />
                    </IconButton>
                </header>
                {fields.phone&&
                <LabelInput
                    id={"phone"}
                    labelValue={"Telefone"}
                    placeholder={"(00) 00000-0000"}
                    value={values.phone}
                    onChange={(value) => handleValueChange("phone", value)}
                    onBlur={(value) => handleBlur("phone", value)}
                    formatValue={(value)=>formatPhoneWithoutIntCode(value)}
                    error={errors.phone}
                    errorMessage={errorMessages.phone}
                />}
                {fields.email&&
                <LabelInput
                    id={"email"}
                    labelValue={"Email"}
                    placeholder={"email@email.com"}
                    value={values.email}
                    onChange={(value) => handleValueChange("email", value)}
                    onBlur={(value) => handleBlur("email", value)}
                    error={errors.email}
                    errorMessage={errorMessages.email}
                />}
                <BaseButton
                    label={getButtonStyle().label}
                    onClick={() => updateSignerData()}
                    className={"signer-data-dialog__btn btn btn--animated "+getButtonStyle().className}
                    loading={requestLoading}
                    isDisabled={getButtonStyle().isDisabled}
                >
                    {getButtonStyle().icon}
                </BaseButton>
                <span className="signer-data-dialog__error error-message">
                    {requestError?"Houve um problema de requisição, tente novamente em instantes. Se não resolver, contate suporte.caas@qitech.com.br":null}
                </span>
            </div>
        </Dialog>
    );
}

export default SignerBatchDataDialog;