import React from "react";
import QILogo from "../assets/images/qitechLogo04.png";

export const TopBar = () => {
    
    return (
        <div className="top-bar">
            <img
                src={QILogo}
                alt="QILogo"
            ></img>
            <div className="top-bar__text">QI Sign</div>
        </div>
    )
}