import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectInput(props) {
  const { items, valueKey, labelKey, onValueChange } = props;

  const [selectedValue, setSelectedValue] = useState(items[0][valueKey]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <Box sx={{ width: "360px", marginBottom: "12px" }}>
      <FormControl fullWidth>
        <Select
          value={selectedValue}
          onChange={handleChange}
          sx={{ fontSize: "12px", height: "40px" }}
        >
          {items.map((item) => (
            <MenuItem
              key={item[valueKey]}
              value={item[valueKey]}
              sx={{ fontSize: "12px" }}
            >
              {item[labelKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
