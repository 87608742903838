import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

function NotificationDialog(props) {

    const {open, setOpen, notificationError, setNotificationError} = props

    const getNotificationMessage = (notificationError) => {
        if (notificationError.hasError) {
            if (notificationError.statusCode === 429) {
                return "Renotificação não atingiu o tempo necessário. Aguarde um instante.";
            } else {
                return "Não é possível reenviar a notificação pois o processo de assinatura está em andamento.";
            }
        } else {
            return "Notificação enviada com sucesso.";
        }
    };

    useEffect(() => {
        let timeoutId;
        let timeoutIdError;
        if (open) {
            timeoutId = setTimeout(() => {
                setOpen(false);
            }, 2000);
        }
        if (!open) {
            timeoutIdError = setTimeout(() => {
                setNotificationError({
                    hasError: false,
                    statusCode: 200
                })
            }, 1000);
        }
        return () => {
            clearTimeout(timeoutId)
            clearTimeout(timeoutIdError)
        };
    }, [open, setOpen, setNotificationError]);

    return (
        <dialog
            className="dialog--temporary"
            open={open}
            onClose={()=>setOpen(false)}
        >
            <div className="dialog__row-container">
                <header>
                    <h2>
                    {getNotificationMessage(notificationError)}
                    </h2>
                </header>
                <IconButton 
                    onClick={()=>setOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </dialog>
    );
}

export default NotificationDialog;