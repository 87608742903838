import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

export const CancelEnvelopeErrorDialog = (props) => {

    const {cancelEnvelopeError, setCancelEnvelopeError} = props

    useEffect(() => {
        let timeoutId;
        if (cancelEnvelopeError) {
            timeoutId = setTimeout(() => {
                setCancelEnvelopeError(false)
            }, 2000);
        }
        return () => {
            clearTimeout(timeoutId)

        };
    }, [cancelEnvelopeError,setCancelEnvelopeError]);

    return (
        <dialog
            className="dialog--temporary"
            open={cancelEnvelopeError}
            onClose={()=>setCancelEnvelopeError(false)}
        >
            <div className="dialog__row-container">
                <header>
                    <h2>
                    Erro ao cancelar o envelope
                    </h2>
                </header>
                <IconButton 
                    onClick={()=>setCancelEnvelopeError(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </dialog>
    );
}