import React, { useState, useEffect, useContext, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage"
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage";
import AuthContext from "../../context/auth-context"
import * as S from './styles'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { DataField } from "../../components/DataField"
import ErrorBoundary from '../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import ContactGroupCreationDialog from './ContactGroupCreationDialog/index.js'
import ContactGroupEditionDialog from './ContactGroupEditionDialog/index.js'
import ContactGroupDeleteDialog from "./ContactGroupDeleteDialog "
import { Pagination } from "../../components/Pagination"
import BaseButton from '../../components/BaseButton'
import EditNoteIcon from '@mui/icons-material/EditNote';
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SearchBar from "../../components/SearchBar/index.js";

export const ContactManagementPage = (props) => {
    
    const userRoles = useContext(AuthContext).user_data.roles
    const company_key = useContext(AuthContext).user_data.company_key

    const navigate = useNavigate()
    if (!userRoles.includes("create_envelopes")){
        navigate("/envelope-inbox")
    }

    const handleRefresh = () => {
        setRefreshPage(!refreshPage)
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const handleContactGroupEditDialog = (contact_group) => {
        setContactGroup(contact_group)
        setOpenContactGroupEditDialog(true)
    };

    const handleContactGroupDeleteDialog = (contact_group) => {
        setContactGroup(contact_group)
        setOpenContactGroupDeleteDialog(true)
    };


    const [contactGroup, setContactGroup] = useState(undefined)
    const [contactGroupsData, setContactGroupsData] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [refreshPage, setRefreshPage] = useState(false)
    const [createContactGroupDialog, setOpenCreateContactGroupDialog] = useState(false);
    const [editContactGroupDialog, setOpenContactGroupEditDialog] = useState(false);
    const [deleteContactGroupDialog, setOpenContactGroupDeleteDialog] = useState(false);


    const sendToContactsPage = (contact_group) => {
        if (contact_group.contact_group_key) {
            navigate(`/contact_group/${contact_group.contact_group_key}/contacts`)
        }
    }

    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })

    const [filterContent, setFilterContent] = useState("");
    const [refresher, setRefresher] = useState(false);
    const [search, setSearch] = useState("");

    const handleSearch = (queryParameter) => {
        setSearch(queryParameter)
    };

    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [config, setConfig] = useState({
        params: {
            page_number: 0,
            page_rows: 7
        }
    })

    const requestContactGroups = useCallback(() => {
        const timer = setTimeout(() => {
            setLoading(true)
            
            const queryString = `${search ? `${search}&` : '?'}page_number=${config.params.page_number}&page_rows=${config.params.page_rows}`;
            
            axios.get(`/dash/sign/company/${company_key}/contact_groups${queryString}`)
                .then((response) => {
                    setContactGroupsData(response.data)
                    setMaxPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 600)
        return () => {
            clearTimeout(timer)
        }
    }, [config, company_key, search])

    useEffect(() => {
        let newConfig
        newConfig = config
        newConfig.params.page_number = currentPage
        setConfig(newConfig)
        requestContactGroups()
    }, [currentPage, config, requestContactGroups, refreshPage])


    if (!userRoles.includes("create_envelopes")) {
        return (
          <S.Container>
            <NoAccessPage />
          </S.Container>
        );
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else {
        return (
            <S.Container>
                <S.SectionHeaderContainer>
                    <S.SectionTitle>
                        Agenda de Contatos
                    </S.SectionTitle>
                    <BaseButton
                        label="Adicionar grupo"
                        onClick={() => setOpenCreateContactGroupDialog(true)}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={false}
                    />
                </S.SectionHeaderContainer>
                <S.FilterContainer>
                    <SearchBar
                        label="Pesquisar grupo de contatos"
                        content={filterContent} 
                        setContent={setFilterContent} 
                        refresher={refresher} 
                        setRefresher={setRefresher}
                        onSearch={handleSearch}
                        variable={"name"}
                    />
                </S.FilterContainer>
                <ul className="inbox">
                    {contactGroupsData.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhum grupo de contato encontrado
                        </span>
                    </p>}
                    {contactGroupsData.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left">Grupo de contatos:</div>
                            <div className="inbox__cell--center"></div>
                            <div className="inbox__cell--right">Gerenciamento:</div>
                        </li>}
                    {contactGroupsData.contact_groups.map((contact_group) => (
                        <li key={contact_group.contact_group_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <div>
                                        <DataField
                                            label="Nome do grupo"
                                            field={contact_group.name}
                                        />
                                    </div>
                                </div>
                                <div className="inbox__cell--center">
                                </div>
                                <div className="inbox__cell--right inbox__cell--row-container">
                                    {contact_group.name !== "Outros" && (
                                        <S.ManagementContainer>
                                            <button
                                                className="btn btn--animated btn--white"
                                                style={{width: "35px", height: "35px"}}
                                                onClick={() => handleContactGroupEditDialog(contact_group)}
                                            >
                                                <EditNoteIcon
                                                    style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                    id={contact_group.contact_group_key}
                                                />
                                            </button>
                                            <S.ManagementDescription>
                                                Editar
                                            </S.ManagementDescription>
                                        </S.ManagementContainer>
                                    )}
                                    {userRoles.includes("cancel_contact_group") && (contact_group.name !== "Outros") && (
                                    <>
                                        <S.ManagementContainer>
                                            <button
                                                className="btn btn--animated btn--white"
                                                style={{width: "38px", height: "38px"}}
                                                onClick={() => handleContactGroupDeleteDialog(contact_group)}
                                            >
                                                <DeleteSweepIcon
                                                    style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                    id={contact_group.contact_group_key}
                                                />
                                            </button>
                                            <S.ManagementDescription>
                                                Deletar
                                            </S.ManagementDescription>
                                        </S.ManagementContainer>
                                    </>)}
                                    <S.ManagementContainer>
                                        <button
                                            className="btn btn--animated btn--white"
                                            style={{width: "35px", height: "35px"}}
                                            id={contact_group.contact_group_key}
                                            onClick={(event) => sendToContactsPage(contact_group)}
                                        >
                                            <ArrowRightIcon
                                                style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                id={contact_group.contact_group_key}
                                            />
                                        </button>
                                        <S.ManagementDescription>
                                            Contatos
                                        </S.ManagementDescription>
                                    </S.ManagementContainer>
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                {createContactGroupDialog &&
                    <ContactGroupCreationDialog
                        open={createContactGroupDialog}
                        setOpen={setOpenCreateContactGroupDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                    />}
                {editContactGroupDialog &&
                    <ContactGroupEditionDialog
                        open={editContactGroupDialog}
                        setOpen={setOpenContactGroupEditDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        contactGroup={contactGroup}
                    />}
                {deleteContactGroupDialog &&
                    <ContactGroupDeleteDialog
                        open={deleteContactGroupDialog}
                        setOpen={setOpenContactGroupDeleteDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        contactGroup={contactGroup}
                    />}
                <Snackbar
                        open={snackBarStatus.opened}
                        autoHideDuration={500}
                        onClose={handleCloseSnackBar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackBar}
                        severity={snackBarStatus["severity"]}
                        style={{fontSize: '12px'}}
                    >
                        {snackBarStatus.message}
                    </MuiAlert>
                </Snackbar>
                <Pagination
                    currentPage={currentPage}
                    maxPage={maxPage}
                    setCurrentPage={setCurrentPage}
                    maxVisiblePages={7}
                />
            </S.Container>
        )
    }
}