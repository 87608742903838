import React, { useContext } from "react"
import LogoutIcon from "@mui/icons-material/Logout"
import { SideBarItems } from "../components/SideBarItem"
import { useNavigate } from "react-router-dom"
import AuthContext from "../context/auth-context"

export const SideBar = (props) => {

    const { sideBarItems } = props
    const navigate = useNavigate()
    const userRoles = useContext(AuthContext).user_data.roles

    const handleLogout = keycloak_adapter => {
        navigate(`/`);
        sessionStorage.clear()
        keycloak_adapter.logout();
    }

    return (
        <AuthContext.Consumer>
            {context => (
            <React.Fragment>
                <div className="side-bar">
                    {sideBarItems.map((item)=>(
                        userRoles.includes(item.role)&&
                        <SideBarItems
                            name={item.name}
                            id={item.id}
                            key={item.id}
                        >
                            {item.icon}
                        </SideBarItems>
                    ))}
                    <div className="side-bar__item-container--to-end">
                        <SideBarItems name="Sair" id="logout" onLogout={()=>handleLogout(context.keycloak_adapter)}>
                            <LogoutIcon style={{width: "1.8rem", marginRight:"1rem"}} onClick={()=>handleLogout(context.keycloak_adapter)}/>
                        </SideBarItems>
                    </div>
                </div>
            </React.Fragment>
            )}
        </AuthContext.Consumer>
    )
}