import styled from "styled-components";

export const ActivateAccountContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const ActivateAccountSectionTitle = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
  padding: 10;
`;

export const ActivateAccountTitle = styled.h1`
  color: #000000;
  font-size: 20px;
  margin: 0.8rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 600;
`;

export const ActivateAccountTitleDescription = styled.p`
    font-size: 16px;
    color: #333333;
    margin: 0.8rem;
    align-items: center;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin: 0.8rem;
    gap: 16px;
    padding: 40px 32px 40px 32px;
    background-color: #eaeaea;
    border-radius: 16px;
    width: 400px;
    filter: drop-shadow(2px 4px 2px #aaa);
`;

export const CardContainerDescription = styled.p`
    margin: 0;
    font-size: 16px;
    color: #0b1f82;
    font-weight: bold;
    width: fit-content;
`;

export const CardContainerDescriptionMinor = styled.p`
    margin: 0;
    font-size: 14px;
    color: #0b1f82;
    width: fit-content;
`;


export const CardNameDescription = styled.p`
    margin: 0;
    font-size: 14px;
    color: #0b1f82;
    width: fit-content;
`;

export const CardItemContainer = styled.div`
  display: grid;
  width: 100%;
  background-color: "#fff";
  align-items:"center";
`;

export const CardImage = styled.div`
  position: relative;
  margin: auto;
  width: 283.2px;
  height: 170.84px;
  background: linear-gradient(89.24deg, #141414 2.21%, #333333 99.34%);
  border-radius: 15px;
`;

export const CardChipImage = styled.div`
  position: absolute;
  width: 35.69px;
  height: 42.06px;
  left: 25px;
  top: 47px;
`;

export const CardNumber = styled.div`
  position: absolute;
  width: auto;
  left: 120px;
  top: 97.2px;
  font-weight: normal;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
`;

export const AddCardButton = styled.div`
    padding: 12px 20px 12px 20px;
    background-color: ${(props) => props.gray ? '#858585' : '#0b1f82'};
    gap: 8px;
    flex-direction: row;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.gray ? '#454545' : '#091968'};
    }
    transition: 0.5s;
`;

export const CardButtonDescription = styled.p`
    color: white;
    font-weight: bold;
    font-size: 14px;
`;

export const RegisterCardModalBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
`;

export const RegisterCardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
`;

export const RegisterCardHeaderDescription = styled.p`
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.2px;
  justify-content: center;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const RegisterCardItems = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: "1";
`;

export const ValidationAlertDescription = styled.p`
  font-weight: 600;
  font-style:  normal;
  color: #d91a1a;
`;

export const ThemeProviderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: "1";
  margin: "10px";
`;

export const ThemeProviderContentItem = styled.div`
  display: flex;
  flex-direction: row;
  margin:"10px 70px 10px 70px";
`;

export const ThemeProviderContentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const ThemeProviderDisabledButton = styled.div`
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #dcdcdc;
  cursor: "wait";
  width: 20%;
`;

export const ThemeProviderAbleButton = styled.div`
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #0b1f82;
  width: 20%;

`;

export const ThemeProviderDescription = styled.p`
  font-size: 13.5px;
  line-height: 24px;
  color: white;
`;

export const MinorModalBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 30px;
  margin-top: 30px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MinorModalButtonContainer = styled.div`
  border-radius: 10px;
  text-align: center;
  width: 100px;
  font-size: 14px;
  line-height: 19px;
  font-family: "Plus Jakarta Sans";
  background-color: #0b1f82;
  cursor: pointer;
`;

export const ModalButtonContainer = styled.div`
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #0b1f82;
  cursor: pointer;
`;

export const ModalButtonDescription = styled.p`
  font-size: 13.5px;
  line-height: 26px;
  color: white;
`;

export const InternalDialogContainerDescription = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  font-family: "Plus Jakarta Sans";
`;