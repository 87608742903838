import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getConfirmationIcon } from "../../utils";
import * as S from "../../../containers/Report/styles";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  },
}));

function DownloadCSVErrorDialog(props) {
  const classes = useStyles();
  useTheme();

  return (
    <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
      <DialogContent className={classes.dialogContent}>
        <S.DialogContainer>
          <S.InternalDialogContainer>
            {getConfirmationIcon(false)}
          </S.InternalDialogContainer>
          <S.InternalDialogContainer>
            <S.InternalDialogContainerDescription>
              Ocorreu um erro durante a geração do arquivo csv.
            </S.InternalDialogContainerDescription>
          </S.InternalDialogContainer>
          <S.InternalDialogContainer>
            <S.InternalDialogContainerDescription>
              Por favor, entre em contato com nosso suporte através do email
              suporte.caas@qitech.com.br, informando esta mensagem{" "}
            </S.InternalDialogContainerDescription>
          </S.InternalDialogContainer>
          <S.InternalDialogContainer style={{ marginTop: "35px" }}>
            <S.FailedDownloadButtonContainer onClick={() => props.onClose()}>
              <S.ModalButtonDescription>Voltar</S.ModalButtonDescription>
            </S.FailedDownloadButtonContainer>
          </S.InternalDialogContainer>
        </S.DialogContainer>
      </DialogContent>
    </Dialog>
  );
}

export default DownloadCSVErrorDialog;
