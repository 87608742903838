import React, { useState, useEffect, useContext, useCallback } from "react"
import axios from "axios"
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage"
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage";
import AuthContext from "../../context/auth-context"
import { DataField } from "../../components/DataField"
import ErrorBoundary from '../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import { Pagination } from "../../components/Pagination"
import { DatetimeBlock } from "../../components/DatetimeBlock"
import { ToastContainer } from 'react-toastify'
import { formatDocumentNumberWithoutPontuaction, formatBirthdateToFront } from "../../utils/utils";
import StatusIconText from "../../components/StatusIconText"
import BaseButton from '../../components/BaseButton'
import { useNavigate } from "react-router-dom"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { CancelSignerBatchErrorDialog } from "./CancelSignerBatchErrorDialog/CancelSignerBatchErrorDialog"
import LoadingIcon from '../../assets/images/loading.svg'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { CircularProgress } from "@mui/material";
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import SendEmailIcon from "@mui/icons-material/ForwardToInbox"
import NotificationDialog from "../Envelope/EnvelopePage/NotificationDialog/NotificationDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SignerBatchDataDialog from "./SignerBatchDataDialog/SignerBatchDataDialog";

export const SignatureAreaPage = (props) => {
    const navigate = useNavigate()
    const userRoles = useContext(AuthContext).user_data.roles
    const companyKey = useContext(AuthContext).user_data.company_key

    const userDocumentNumber = useContext(AuthContext).user_data.document_number

    const document_number = formatDocumentNumberWithoutPontuaction(userDocumentNumber)

    const sendToSignerBatchCreationPage = () => {
        navigate(`/signature-area-creation`)
    }
    
    const [fetchedData, setFetchedData] = useState(undefined)
    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [cancelSignerBatchError, setCancelSignerBatchError] = useState(false)
    const [notificationSignerBatchKey, setNotificationSignerBatchKey] = useState(undefined)

    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [config, setConfig] = useState({
        params: {
            page_number: 0,
            page_rows: 7
        }
    })


    const [notificationError, setNotificationError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [notificationLoading, setNotificationLoading] = useState(false)
    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)

    const cancelSignerBatch = async (signerBatchKey) => {
        const submissionData = {
            "status": "canceled"
        }
        setCancelLoading(true)
        const timer = setTimeout(() => {
            axios.patch(`/dash/sign/company/${companyKey}/signer_batch/${signerBatchKey}`, submissionData)
                .then((response) => {
                    window.location.reload()
                })
                .catch((error) => {
                    console.log(error)
                    setCancelSignerBatchError(true)
                })
                .finally(() => {
                    setLoading(false)
                    setCancelLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }

    const resendNotification = (signerBatchKey) => {
        let data = {}
        setNotificationSignerBatchKey(signerBatchKey)
        setNotificationLoading(true)
        const timer = setTimeout(() => {
            axios.patch(`/dash/sign/company/${companyKey}/signer_batch/${signerBatchKey}/notify`, data)
                .then((response) => {
                    setNotificationError({
                        hasError: false,
                        statusCode: 200
                    })
                    setNotificationDialogOpen(true)
                })
                .catch((error) => {
                    setNotificationError({
                        hasError: true,
                        statusCode: error.response.status
                    })
                })
                .finally(() => {
                    setNotificationLoading(false)
                    setNotificationDialogOpen(true)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }

        const isNotUndefined = (value) => {
        if (value) return true
        else return false
    }


    const [editSignerBatchDataDialogOpen, setEditSignerBatchDataDialogOpen] = useState(true)
    const [signerBatchKey, setSignerBatchKey] = useState(undefined)
    const [signerBatchName, setSignerBatchName] = useState(undefined)
    const [dialogValues, setDialogValues] = useState(undefined)
    const [dialogFields, setDialogFields] = useState(undefined)

    const editSignerBatchDataHandler = (signerBatchKey) => {
        setSignerBatchKey(signerBatchKey)
        const signerBatch = fetchedData.signer_batches.filter((signer_batch) => (signer_batch.signer_batch_key === signerBatchKey))[0]
        setSignerBatchName(signerBatch.name)
        setDialogFields({
            phone: isNotUndefined(signerBatch.phone),
            email: isNotUndefined(signerBatch.email)
        })

        let values = {}
        if (isNotUndefined(signerBatch.phone)) {
            const areaCode = signerBatch.phone.area_code
            const number = `${signerBatch.phone.number.slice(0, 5)}-${signerBatch.phone.number.slice(5, 9)}`
            values["phone"] = `$(${areaCode})${number}`
        }
        if (isNotUndefined(signerBatch.email)) {
            values["email"] = signerBatch.email
        }
        setDialogValues(values)

        setEditSignerBatchDataDialogOpen(true)
    }

    const copySignerBatchSignatureUrl = (signerBatchKey) => {
        const signerBatch = fetchedData.signer_batches.filter((signer_batch) => (signer_batch.signer_batch_key === signerBatchKey))[0]
        const url = signerBatch.signature_url

        const input = document.createElement('input')
        input.setAttribute('value', url)
        document.body.appendChild(input)

        input.select()
        document.execCommand('copy')

        document.body.removeChild(input)
    }

    const requestEnvelopes = useCallback(() => {
        const timer = setTimeout(() => {

            const queryString = `page_number=${config.params.page_number}&page_rows=${config.params.page_rows}&document_number=${document_number}`;

            axios.get(`/dash/sign/company/${companyKey}/signer_batches?${queryString}`)
                .then((response) => {
                    setFetchedData(response.data)
                    setMaxPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    if ([404].includes((error.response || {}).status)) {
                        setError({
                            "has_error": true,
                            "status": error.response.status
                        })
                    } else {
                        setError({
                            "has_error": true,
                            "status": 500
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [config, companyKey, document_number])

    useEffect(() => {
        let newConfig
        newConfig = config
        newConfig.params.page_number = currentPage
        setConfig(newConfig)
        requestEnvelopes()
    }, [currentPage, config, requestEnvelopes])

    if (!userRoles.includes("create_signer_batch")) {
        return (
            <NoAccessPage />
        )
    }

    if (loading) {
        return (
            <div className="page-container">
                <header>
                    <h1>
                        Listagem de Envelopes
                    </h1>
                </header>
                <LoadingPage />
            </div>
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else if (fetchedData) {
        return (
            <div className="page-container">
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <header>
                    <h1>
                        Área de Assinatura em Lote
                    </h1>
                </header>
                {userRoles.includes("create_signer_batch") &&
                    <BaseButton
                        label="Criar assinatura em lote"
                        onClick={() => sendToSignerBatchCreationPage()}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={false}
                    />}
                {fetchedData.signer_batches.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhuma assinatura em lote
                        </span>
                    </p>}
                <ul className="inbox">
                    {fetchedData.signer_batches.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left">Criado em:</div>
                            <div className="inbox__cell--center">Informações:</div>
                            <div className="inbox__cell--right">Status:</div>
                        </li>}
                    {fetchedData.signer_batches.map((signer_batch) => (
                        <li key={signer_batch.signer_batch_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <DatetimeBlock dateString={signer_batch.created_at} />
                                </div>
                                <div className="inbox__cell--center">
                                    <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                                        {userRoles.includes("update_signer_data")
                                            && (["on_signature", "submitted", "created"].includes(signer_batch.signer_batch_signature_status)) ?
                                            <button
                                                className="btn btn--white btn--animated"
                                                onClick={() => editSignerBatchDataHandler(signer_batch.signer_batch_key)}
                                            >
                                                <EditNoteIcon id={signer_batch.signer_batch_key} style={{ width: "1.8rem" }} />
                                            </button>
                                            : <div className="envelope__signer-cell-btn--width"></div>}
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            {signer_batch.document_number &&
                                                <DataField
                                                        label="Documento"
                                                        field={signer_batch.document_number}
                                                    />}
                                            {signer_batch.birthdate &&
                                                <DataField
                                                    label="Data de Nascimento"
                                                    field={formatBirthdateToFront(signer_batch.birthdate)}
                                                />}
                                            {signer_batch.email &&
                                                <DataField
                                                    label="Email"
                                                    field={signer_batch.email}
                                                />}
                                            {signer_batch.phone &&
                                                <DataField
                                                    label="Telefone"
                                                    field={`${signer_batch.phone.international_dial_code} (${signer_batch.phone.area_code}) ${signer_batch.phone.number.slice(0, 5)}-${signer_batch.phone.number.slice(5, 9)}`}
                                                />} 
                                        </div>
                                    </div>
                                </div>
                                <div className="inbox__cell--right inbox__cell--row-container">
                                {userRoles.includes("renotify_signer") && (signer_batch.signer_batch_signature_status === "on_signature") &&
                                    <button className="btn btn--animated btn--white" onClick={() => !notificationLoading ? resendNotification(signer_batch.signer_batch_key) : null}>
                                            {(notificationLoading&&notificationSignerBatchKey===signer_batch.signer_batch_key)?
                                            <CircularProgress style={{ color: "#2d2d2d" }} size={"1.6rem"} />
                                            :
                                            (signer_batch.document_submission_method==="sms" || signer_batch.document_submission_method==="whatsapp")?
                                                <SendToMobileOutlinedIcon style={{ width: "1.8rem", color: "#525252" }} />
                                                :<SendEmailIcon style={{ width: "1.8rem", color: "#525252" }} />
                                            }
                                    </button>}
                                    <button className="btn btn--animated btn--white" onClick={() => copySignerBatchSignatureUrl(signer_batch.signer_batch_key)}>
                                            <ContentCopyIcon style={{ width: "1.8rem", color: "#525252" }} />
                                    </button>
                                    <StatusIconText
                                        id={signer_batch.signer_batch_key}
                                        status={signer_batch.signer_batch_status}
                                        type="signer_batch_status"
                                    />
                                    {(["created"].includes(signer_batch.signer_batch_status)) && (
                                        <div>
                                            <BaseButton
                                                label="Cancelar"
                                                className="btn__container--center btn btn--red btn--animated"
                                                onClick={() => cancelSignerBatch(signer_batch.signer_batch_key)}
                                            >
                                                {cancelLoading ?
                                                    <img className="btn__icon" src={LoadingIcon} alt="Loading" />
                                                    :
                                                <CancelRoundedIcon style={{ width: "1.8rem", height: "1.8rem" }}/>}
                                            </BaseButton>
                                        </div>
                                    )}
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                <CancelSignerBatchErrorDialog
                    cancelSignerBatchError={cancelSignerBatchError}
                    setCancelSignerBatchError={setCancelSignerBatchError}
                />
                {signerBatchKey && signerBatchName && dialogFields &&
                    <SignerBatchDataDialog
                        open={editSignerBatchDataDialogOpen}
                        setOpen={setEditSignerBatchDataDialogOpen}
                        signerBatchKey={signerBatchKey}
                        setSignerBatchKey={setSignerBatchKey}
                        companyKey={companyKey}
                        signerBatchName={signerBatchName}
                        setSignerBatchName={setSignerBatchName}
                        values={dialogValues}
                        setValues={setDialogValues}
                        fields={dialogFields}
                />}
                <NotificationDialog
                    open={notificationDialogOpen}
                    setOpen={setNotificationDialogOpen}
                    notificationError={notificationError}
                    setNotificationError={setNotificationError}
                />
                <ErrorBoundary>
                    <Pagination
                        currentPage={currentPage}
                        maxPage={maxPage}
                        setCurrentPage={setCurrentPage}
                        maxVisiblePages={7}
                    />
                </ErrorBoundary>
            </div>
        )
    }
}