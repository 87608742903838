import React from "react";
import { formatDate } from "../utils/utils";

export const FilterDate = (props) => {
    
    const { id, labelValue, filters, setFilters } = props

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: formatDate(value)
        });
    }

    return (
        <div className="filter">
            <label htmlFor={id}>{labelValue}:</label>
            <input
                name={id}
                value={formatDate(filters[id])}
                onChange={handleInputChange}
                placeholder="DD/MM/YYYY"
                pattern="\d{2}/\d{2}/\d{4}" required
            />
        </div>
    );
}

export default FilterDate;