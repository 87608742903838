import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`;

export const SectionHeaderContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
  padding: 10;
`;

export const SectionTitle = styled.p`
  color: #000000;
  font-size: 20px;
  margin: 0.8rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 600;
`;

export const UserListContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
  padding: 10;
`;

export const UserListHeaderContainer = styled.ul`
  width: calc(100% - 2rem);
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem;
  font-weight: bold;
`;

export const UserListHeaderItem = styled.li`
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem;
  font-weight: bold;
`;


export const PermissionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
`;

export const Input = styled.input`
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

export const PermissionDescription = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
`;

export const SwitchContainer = styled.div`
  width: 65px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ManagementContainer = styled.div`
  width: 80%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-items: space-between;
  margin: 0.8rem;
  padding: 10;
`;

export const ManagementDescription = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin: 4px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;