import React, { useState } from "react";

export const LabelInput = (props) => {
    
    const { id, labelValue, value, onChange, onBlur, error, errorMessage} = props

    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (props.formatValue) {
            onChange(props.formatValue(newValue));
        } else {
            onChange(newValue);
        }
    };

    const handleBlur = (event) => {
        setIsFocused(false);
        onBlur(event.target.value);
    };
    
    const handleFocus = () => {
        setIsFocused(true);
    };
    

    return (
        <div className="label-input">
            <label htmlFor={id}>{labelValue}:</label>
            <input
                id={!isFocused&&error?"error":id}
                placeholder={props.placeholder?props.placeholder:null}
                value={(props.formatValue)?props.formatValue(value):value}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            <span className="error-message" style={{height:"13px"}}>{!isFocused&&error?errorMessage:null}</span>
        </div>
    );
}
