import styled from "styled-components";

export const ReportContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const ReportSectionTitle = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
  padding: 10;
`;

export const ReportTemplateSectionTitle = styled.h1`
  color: #000000;
  align-items: center;
  font-size: 20px;
  margin: 0.8rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 600;
`;

export const ReportTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2rem);
  margin: 0.8rem;
`;

export const ReportTemplateDescription = styled.p`
  color: #000000;
  font-size: 16px;
  margin: 0.8rem;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
`;

export const ReportTemplateItemsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto auto;
`;

export const ReportTemplateButton = styled.div`
  width: calc(100% - 16px);
  padding: 8px 16px;
  margin: 8px;
  cursor: pointer;
  text-align: center;
  background-color: #0b1f82;
  color: #fff;
  font-size: 13.5px;
  border-radius: 24px;
  &:hover {
    background-color: #020f4d;
  }
  transition: 0.25s;
`;

export const ReportNoContentDescription = styled.p`
  color: #0b1f82;
  font-size: 13.5px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
`;

export const ReportTemplateItemDescription = styled.p`
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: 500;
`;

export const ReportListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2rem);
  margin: 0.8rem;
`;

export const ReportListDescription = styled.p`
  color: #000000;
  font-size: 16px;
  margin: 0.8rem;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
`;

export const ReportListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.8rem;
`;

export const ReportListHeader = styled.div`
  width: 100%;
  display: flex;
  margin: 0.8rem;
  justify-content: space-around;
`;

export const ReportListHeaderItem = styled.p`
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 14px;
  margin: 0.8rem;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
`;

export const DialogContainer = styled.div`
  width: 330px;
  height: 267px;
  text-align: center;
  margin-bottom: 10px;
`;

export const InternalDialogContainer = styled.div`
  display: flex;
  height: 50px;
  margin-top: 25px;
  justify-content: center;
  text-align: center;
`;

export const InternalDialogContainerDescription = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  font-family: "Plus Jakarta Sans";
`;

export const CsvContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const CsvButtonContainer = styled.div`
  padding: 5px 0px;
  margin: 5px;
`;

export const FilterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8rem;
`;

export const FilterListHeader = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.2px;
  justify-content: left;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const FilterListItemDescription = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

export const CsvButtonProcessingContainer = styled.div`
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #dcdcdc;
`;

export const CsvProcessingButtonDescription = styled.p`
  font-size: 13.5px;
  line-height: 24px;
  color: black;
`;
export const CsvButtonExportContainer = styled.div`
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #0b1f82;
`;

export const CsvExportButtonDescription = styled.p`
  font-size: 13.5px;
  line-height: 24px;
  color: white;
`;

export const ModalBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
`;

export const MinorModalBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 30px;
  margin-top: 30px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MinorModalButtonContainer = styled.div`
  border-radius: 10px;
  text-align: center;
  width: 100px;
  font-size: 14px;
  line-height: 19px;
  font-family: "Plus Jakarta Sans";
  background-color: #0b1f82;
  cursor: pointer;
`;

export const ModalButtonContainer = styled.div`
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #0b1f82;
  cursor: pointer;
`;

export const ModalButtonDescription = styled.p`
  font-size: 13.5px;
  line-height: 26px;
  color: white;
`;

export const FilterBoxContainer = styled.div`
  width: auto;
  display: flex;
  font-size: 12px;
  line-height: 16px;
`;

export const FilterBox = styled.div`
  float: left;
  margin: 2px 4px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  max-height: 48px;
`;

export const FilterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const FilterContainer = styled.div`
  background: white;
  padding: 4px 15px;
  display: flex;
  justify-content: space-between;
  width: 350px;
  align-items: center;
  border-radius: 4px;
  float: left;
  margin: 2px 4px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
`;

export const FilterContainerDescription = styled.p`
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  float: left;
  white-space: nowrap;
`;

export const FailedDownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  height: 40px;
  font-size: 14px;
  line-height: 19px;
  font-family: "Plus Jakarta Sans";
  background-color: #0b1f82;
  cursor: pointer;
`;
