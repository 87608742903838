import React, { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";

export default function PDFContent({ pdfFile }) {
    const [pdfFileData, setPdfFileData] = useState(null);

    const pdfStyle = {
        borderRadius: "4px",
        margin: "auto",
        width: "600px",
        height: "700px"
    };

    const renderPdf = (uint8array) => {
        const tempBlob = new Blob([uint8array], { type: "application/pdf" });
        const documentUrl = URL.createObjectURL(tempBlob);
        setPdfFileData(documentUrl);
    };

    const range = (start, end) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i - 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            const extractPdfPage = async () => {
                const pdfSrcDoc = await PDFDocument.load(pdfFile);
                const pdfNewDoc = await PDFDocument.create();
                const pages = await pdfNewDoc.copyPages(pdfSrcDoc, range(1, pdfSrcDoc.getPageCount()));
                pages.forEach((page) => pdfNewDoc.addPage(page));
                const newPdf = await pdfNewDoc.save();
                return newPdf;
            };

            const newPdfDoc = await extractPdfPage();
            renderPdf(newPdfDoc);
        };

        if (pdfFile) {
            fetchData();
        }
    }, [pdfFile]);

    if (!pdfFileData) {
        return null;
    }

    return (
        <iframe
            style={pdfStyle}
            title="PdfFrame"
            src={pdfFileData}
            type="application/pdf"
        ></iframe>
    );
}
