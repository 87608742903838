import React, { useState, useEffect, useContext, useCallback } from "react"
import axios from "axios"
import { ErrorPage } from "../../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../../AuxiliarPages/LoadingPage/LoadingPage"
import { NoAccessPage } from "../../AuxiliarPages/NoAccessPage/NoAccessPage";
import AuthContext from "../../../context/auth-context"
import { DataField } from "../../../components/DataField"
import ErrorBoundary from '../../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import { Pagination } from "../../../components/Pagination"
import BaseButton from '../../../components/BaseButton'
import { DatetimeBlock } from "../../../components/DatetimeBlock"
import { ToastContainer } from 'react-toastify'
import { formatDocumentNumberWithoutPontuaction } from "../../../utils/utils";
import { Checkbox } from '@mui/material'
import SignerBatchCreationDialog from "../SignerBatchCreationDialog/SignerBatchCreationDialog";

export const SignatureAreaCreation = (props) => {
    const userRoles = useContext(AuthContext).user_data.roles
    const userDocumentNumber = useContext(AuthContext).user_data.document_number
    const document_number = formatDocumentNumberWithoutPontuaction(userDocumentNumber)
    
    const [openSignerBatchCreationDialog, setOpenSignerBatchCreationDialog] = useState(false)
    const [fetchedData, setFetchedData] = useState(undefined)
    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [config, setConfig] = useState({
        params: {
            page_number: 0,
            page_rows: 7
        }
    })

    const [selectAllEnvelopesPage, setSelectAllEnvelopesPage] = useState(false);  
    const [selectedEnvelopes, setSelectedEnvelopes] = useState([]);
    const [envelopeSelectionsByPage, setEnvelopeSelectionsByPage] = useState({});
    
    useEffect(() => {
        const currentEnvelopes = fetchedData?.envelopes;
        const currentPageSelections = envelopeSelectionsByPage[currentPage] || [];
        if (currentEnvelopes?.length > 0) {
            setSelectAllEnvelopesPage(currentPageSelections?.length === currentEnvelopes?.length);
        }
    }, [currentPage,fetchedData, envelopeSelectionsByPage]);

    useEffect(() => {
        const allSelectedEnvelopes = Object.values(envelopeSelectionsByPage).flat();
        setSelectedEnvelopes(allSelectedEnvelopes);
    }, [envelopeSelectionsByPage]);

    const handleCheckboxChange = (envelope) => {
        setEnvelopeSelectionsByPage(prev => {
            const updatedSelections = { ...prev };
            const currentSelections = updatedSelections[currentPage] || [];

            if (currentSelections.some(selectedEnvelope => selectedEnvelope.envelope_key === envelope.envelope_key)) {
                updatedSelections[currentPage] = currentSelections.filter(selectedEnvelope => selectedEnvelope.envelope_key !== envelope.envelope_key);
            } else {
                updatedSelections[currentPage] = [...currentSelections, envelope];
            }

            return updatedSelections;
        });
    };

    const handleSelectAllEnvelopesPageChange = () => {
        const currentEnvelopes = fetchedData.envelopes;

        setEnvelopeSelectionsByPage(prev => {
            const updatedSelections = { ...prev };

            if (selectAllEnvelopesPage) {
                delete updatedSelections[currentPage];
            } else {
                updatedSelections[currentPage] = currentEnvelopes;
            }

            return updatedSelections;
        });

        setSelectAllEnvelopesPage(!selectAllEnvelopesPage);
    };

    const verifyEnvelopeOnCheckbox = (envelope_key) => {
        for (let index = 0; index < selectedEnvelopes.length; index++) {
            if (selectedEnvelopes[index].envelope_key === envelope_key) {
                return true;
            }
        }
        return false;
    };
    
    const requestEnvelopes = useCallback(() => {
        const timer = setTimeout(() => {

            const queryString = `page_number=${config.params.page_number}&page_rows=${config.params.page_rows}&signer_batch=True&status=on_signature&document_number=${document_number}`;

            axios.get(`/dash/sign/envelopes?${queryString}`)
                .then((response) => {
                    setFetchedData(response.data)
                    setMaxPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    if ([404].includes((error.response || {}).status)) {
                        setError({
                            "has_error": true,
                            "status": error.response.status
                        })
                    } else {
                        setError({
                            "has_error": true,
                            "status": 500
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [config, document_number])

    useEffect(() => {
        let newConfig
        newConfig = config
        newConfig.params.page_number = currentPage
        setConfig(newConfig)
        requestEnvelopes()
    }, [currentPage, config, requestEnvelopes])

    if (!userRoles.includes("create_signer_batch")) {
        return (
            <NoAccessPage />
        )
    }
    
    if (loading) {
        return (
            <div className="page-container">
                <header>
                    <h1>
                        Listagem de Envelopes
                    </h1>
                </header>
                <LoadingPage />
            </div>
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else if (fetchedData) {
        return (
            <div className="page-container">
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <header>
                    <h1>
                        Listagem de Envelopes
                    </h1>
                </header>
                <BaseButton
                        label="Assinar Envelopes"
                        onClick={() => setOpenSignerBatchCreationDialog(true)}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={selectedEnvelopes.length === 0}
                    />
                <div style={{ display: 'flex', fontSize: "12px", alignItems: 'center', gap: '8px', marginLeft: '24px', marginBottom: '4px' }}>
                        <Checkbox
                            checked={selectAllEnvelopesPage}
                            onClick={handleSelectAllEnvelopesPageChange}
                        />
                        <p style={{ margin: 0, color: "#515151"}}>Selecionar todos nessa página</p>
                </div>
                {fetchedData.envelopes.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhum envelope
                        </span>
                    </p>}
                <ul className="inbox">
                    {fetchedData.envelopes.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left">Criado em:</div>
                            <div className="inbox__cell--center">Remetente:</div>
                            <div className="inbox__cell--right">Expiração:</div>
                        </li>}
                    {fetchedData.envelopes.map((envelope) => (
                        <li key={envelope.envelope_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <Checkbox
                                        checked={verifyEnvelopeOnCheckbox(envelope.envelope_key)}
                                        onClick={() => handleCheckboxChange(envelope)}
                                        onChange={() => {}}
                                    />
                                    <DatetimeBlock dateString={envelope.created_at} />
                                    <div>
                                        <DataField
                                            label="Nome do Envelope"
                                            field={envelope.subject}
                                        />
                                        <DataField
                                            label="ID do Envelope"
                                            field={envelope.id}
                                        />
                                    </div>
                                </div>
                                <div className="inbox__cell--center">
                                        <p
                                            className="inbox__cell--normal-text"
                                        >
                                            {`${envelope.company_name}`}
                                        </p>
                                </div>
                                <div className="inbox__cell--right inbox__cell--row-container">
                                    <DatetimeBlock dateString={envelope.expiration_date} />
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                <ErrorBoundary>
                    <Pagination
                        currentPage={currentPage}
                        maxPage={maxPage}
                        setCurrentPage={setCurrentPage}
                        maxVisiblePages={7}
                    />
                </ErrorBoundary>
                {openSignerBatchCreationDialog &&
                    <SignerBatchCreationDialog
                        open={openSignerBatchCreationDialog}
                        setOpen={setOpenSignerBatchCreationDialog}
                        selectedEnvelopes={selectedEnvelopes}
                    />}
            </div>
        )
    }
}