import React from 'react';
import { backEnvelopeStatusToFront, frontEnvelopeStatusToStyle, backSignerStatusToFront, frontSignerStatusToStyle, backSignerBatchStatusToFront, frontSignerBatchStatusToStyle } from '../utils/utils';

function StatusIconText (props) {
  
  const { id, status, type } = props

  let frontStatus
  let statusStyle

  if (type==="signer_status") {
    frontStatus = backSignerStatusToFront[status]
    statusStyle = frontSignerStatusToStyle[frontStatus]
  }
  else if (type==="envelope_status") {
    frontStatus = backEnvelopeStatusToFront[status]
    statusStyle = frontEnvelopeStatusToStyle[frontStatus]
  }
  else if (type==="signer_batch_status") {
    frontStatus = backSignerBatchStatusToFront[status]
    statusStyle = frontSignerBatchStatusToStyle[frontStatus]
  }

  return (
    <div id={id?id:null} className={([statusStyle.backgroundColor]&&[statusStyle.backgroundColor])+" status-icon-text"}>
      {statusStyle.icon?statusStyle.icon:null}
      <p id={id?id:null}>
        {statusStyle.text?statusStyle.text:status}
      </p>
    </div>
  )
}

export default StatusIconText;
