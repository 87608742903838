import React from "react";

export const FilterText = (props) => {
    
    const { id, labelValue, filters, setFilters } = props

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: props.formatter?props.formatter(value):value
        });
    }

    return (
        <div className="filter">
            <label htmlFor={id}>{labelValue}:</label>
            <input
                name={id}
                placeholder={props.placeholder?props.placeholder:null}
                value={props.formatter?props.formatter(filters[id]):filters[id]}
                onChange={handleInputChange}
            />
        </div>
    );
}

export default FilterText;