import React, { useState, useContext } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthContext from "../../../context/auth-context";
import * as S from '../styles'

function ContactDeleteDialog(props) {
  const { open, setOpen, setSnackBarController, handleRefresh, contactGroupKey, contact } = props;

  const company_key = useContext(AuthContext).user_data.company_key;

  const [loading, setLoading] = useState(false);

  const [snackBarStatus, setSnackBarStatus] = useState({
    opened: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackBarStatus({ ...snackBarStatus, opened: false });
  };

  const primaryColor = "#cc082c";


  const handleSubmit = async () => {
    setLoading(true);

    axios
      .delete(`/dash/sign/company/${company_key}/contact_group/${contactGroupKey}/contact/${contact.contact_key}`)
      .then((response) => {
        setSnackBarController({
          opened: true,
          severity: "success",
          message: "Contato removido com sucesso",
        });
        setOpen(false);
        handleRefresh();
      })
      .catch((error) => {
        setSnackBarStatus({
          opened: true,
          severity: "error",
          message: "Ocorreu um erro ao remover o contato",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            width: "400px",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress />
        </div>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open}>
        <div style={{ width: "400px", padding: "2rem" }}>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setOpen(false);
            }}
            style={{ float: "right", width: "30px", height: "30px" }}
          >
            <CloseIcon />
          </IconButton>
          <h2 style={{ marginBottom: "1.5rem" }}>Remoção do Contato</h2>
          <S.DeleteContactDescription>
            Você tem certeza que deseja remover esse contato?
          </S.DeleteContactDescription>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{
                width: "100%",
                height: "32px",
                fontSize: "12px",
                margin: "1rem 0",
                backgroundColor: primaryColor,
                color: "white",
              }}
            >
              Remover
            </Button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackBarStatus.opened}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackBarStatus.severity}
        >
          {snackBarStatus.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContactDeleteDialog;
