import React, { useState, useContext } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, CircularProgress } from "@mui/material";
import { LabelInput } from "../../../components/LabelInput";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthContext from "../../../context/auth-context";
import * as S from '../styles'

function ContactGroupDeleteDialog(props) {
  const { open, setOpen, setSnackBarController, handleRefresh, contactGroup } = props;

  const company_key = useContext(AuthContext).user_data.company_key;

  const [loading, setLoading] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  const handleBlur = (value) => {
    setConfirmationText(value);
  };

  const [snackBarStatus, setSnackBarStatus] = useState({
    opened: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackBarStatus({ ...snackBarStatus, opened: false });
  };

  const primaryColor = "#cc082c";

  const handleInputChange = (value) => {
    setConfirmationText(value);
  };

  const handleSubmit = async () => {
    if (confirmationText !== "Delete me") {
      setSnackBarStatus({
        opened: true,
        severity: "error",
        message: "Digite 'delete me' para confirmar a remoção",
      });
      return;
    }

    setLoading(true);

    axios
      .delete(`/dash/sign/company/${company_key}/contact_group/${contactGroup.contact_group_key}`)
      .then((response) => {
        setSnackBarController({
          opened: true,
          severity: "success",
          message: "Grupo de contatos removido com sucesso",
        });
        setOpen(false);
        handleRefresh();
      })
      .catch((error) => {
        setSnackBarStatus({
          opened: true,
          severity: "error",
          message: "Ocorreu um erro ao remover o grupo de contatos",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            width: "400px",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress />
        </div>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open}>
        <div style={{ width: "400px", padding: "2rem" }}>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setOpen(false);
            }}
            style={{ float: "right", width: "30px", height: "30px" }}
          >
            <CloseIcon />
          </IconButton>
          <h2 style={{ marginBottom: "1.5rem" }}>Remoção do Grupo de Contatos</h2>
          <S.DeleteDescription>
            Digite "Delete me" para confirmar a remoção do grupo de contatos.
          </S.DeleteDescription>
          <LabelInput
            labelValue="Remoção do grupo"
            placeholder="Delete me"
            value={confirmationText}
            onBlur={(value) => handleBlur(value)}
            onChange={(value) => handleInputChange(value)}
            error={false}
          />
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={confirmationText !== "Delete me"}
              style={{
                width: "100%",
                height: "32px",
                fontSize: "12px",
                margin: "1rem 0",
                backgroundColor: primaryColor,
                color: "white",
              }}
            >
              Remover
            </Button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackBarStatus.opened}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackBarStatus.severity}
        >
          {snackBarStatus.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContactGroupDeleteDialog;
