import React, { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "../../context/auth-context";
import axios from "axios";
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage";
import * as S from "./styles";
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage";
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage";
import { useNavigate } from "react-router-dom";
import whiteChipIcon from "../../assets/images/cards/whiteChip.svg";
import RegisterCardDialog from "./RegisterCardDialog/RegisterCardDialog";
import pagarme from "pagarme/browser";
import { CircularProgress } from '@material-ui/core';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const ActivateInbox = () => {

  const minorboxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const navigate = useNavigate();
  const userRoles = useContext(AuthContext).user_data.roles;
  const company_key = useContext(AuthContext).user_data.company_key;

  if (!userRoles.includes("selfservice_customer")) {
    navigate("/envelope-inbox");
  }

  const [companyData, setCompanyData] = useState(undefined);
  const [cardActiveData, setCardActiveData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [openNewCardDialog, setOpenNewCardDialog] = useState(false);
  const [processingFirstTransaction, setProcessingFirstTransaction] = useState(false);
  const [firstTransactionSuccess, setFirstTransactionSuccessModal] = useState(false);
  const [firstTransactionFailed, setFirstTransactionFailedModal] = useState(false);

  const closeFirstTransactionSuccessModal = () => {
    setFirstTransactionSuccessModal(false);
    window.location.reload();
  };

  const closeFirstTransactionFailedModal = () => {
    setFirstTransactionFailedModal(false);
    window.location.reload();
  };

  const [error, setError] = useState({
    hasError: false,
    statusCode: 200,
  });

  const requestCompanyData = useCallback((company_key) => {
    const timer = setTimeout(() => {
      axios
        .get(`/dash/sign/company/${company_key}`)
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          if ([404].includes((error.response || {}).status)) {
            setError({
              has_error: true,
              status: error.response.status,
            });
          } else {
            setError({
              has_error: true,
              status: 500,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const requestActiveCardData = useCallback(() => {
    const timer = setTimeout(() => {
      axios
        .get(`/dash/billing/card`)
        .then((response) => {
          setCardActiveData(response.data);
        })
        .catch((error) => {
          if ([404].includes((error.response || {}).status)) {
            setError({
              has_error: true,
              status: error.response.status
            })
          } else {
            setError({
              has_error: true,
              status: 500,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

      
  const handleCardEncryption = (card_object) => {
    setLoading(true)
    let card = {
      "card_holder_name": card_object.card_holder_name,
      "card_number": card_object.card_number,
      "card_expiration_date": card_object.card_expiration_date,
      "card_cvv": card_object.card_cvv
    }
  
    pagarme.client.connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY })
      .then(client => {
        return client.security.encrypt(card)
      })
      .then(card_hash => {
        axios.post('/dash/billing/card', { card_hash: card_hash, card_name: card_object.card_name })
          .then(response => {
            requestActiveCardData()
          })
          .catch(error => {
            if ([400].includes((error.response || {}).status)) {
              setError({
                "has_error": true,
                "status": error.response.status
              })
            } else {
              setError({
                "has_error": true,
                "status": 500
              })
            }
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(error => {
        setError({
          "has_error": true,
          "status": 500
        })
      });
  }

  useEffect(() => {
    requestActiveCardData();
    requestCompanyData(company_key);
  }, [requestCompanyData, requestActiveCardData, company_key]);

  const requestFirstTransaction = () => {
    setProcessingFirstTransaction(true)
    const timer = setTimeout(() => {
      axios
        .post(`/dash/companies/first_transaction`, {})
        .then((response) => {
          setFirstTransactionSuccessModal(true)
        })
        .catch((error) => {
          if ([409, 401, 404].includes((error.response || {}).status)) {
            setError({
              has_error: true,
              status: error.response.status,
            });
          } else {
            setError({
              has_error: true,
              status: 500,
            });
          }
        })
        .finally(() => {
          setProcessingFirstTransaction(false);
        });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  if (!userRoles.includes("selfservice_customer")) {
    return (
      <S.ActivateAccountContainer>
        <NoAccessPage />
      </S.ActivateAccountContainer>
    );
  }
  if (loading) {
    return (
      <S.ActivateAccountContainer>
        <LoadingPage />
      </S.ActivateAccountContainer>
    );
  } else if (error.hasError) {
    return (
      <S.ActivateAccountContainer>
        <ErrorPage />
      </S.ActivateAccountContainer>
    );
  } else if (companyData) {
    return (
      <>
        {userRoles.includes("selfservice_customer") && companyData.is_demo ? (
          <S.ActivateAccountContainer>
            <S.ActivateAccountSectionTitle>
              <S.ActivateAccountTitle>Ativação da conta</S.ActivateAccountTitle>
              <S.ActivateAccountTitleDescription>
                Para ativar sua conta, realize o pagamento do seu plano e
                aproveite os benefícios do Qi Sign.
              </S.ActivateAccountTitleDescription>
            </S.ActivateAccountSectionTitle>
            {cardActiveData ? (
              <S.CardContainer>
                <S.CardContainerDescription>
                  Cartão de crédito cadastrado
                </S.CardContainerDescription>
                <S.CardNameDescription>
                  {cardActiveData.card_name}
                </S.CardNameDescription>
                <S.CardItemContainer>
                  <S.CardImage>
                    <S.CardChipImage>
                      <img src={whiteChipIcon} alt="chip Logo" />
                      <S.CardNumber>
                        {`••••••••••••${cardActiveData.card_last_four_digit}`}
                      </S.CardNumber>
                    </S.CardChipImage>
                  </S.CardImage>
                </S.CardItemContainer>
                <S.AddCardButton onClick={() => setOpenNewCardDialog(true)}>
                  <S.CardButtonDescription>
                    Cadastrar outro cartão
                  </S.CardButtonDescription>
                </S.AddCardButton>
                {openNewCardDialog && (
                  <>
                    <RegisterCardDialog
                      open={openNewCardDialog}
                      onClose={() => setOpenNewCardDialog(false)}
                      onSuccess={handleCardEncryption}

                    >
                    </RegisterCardDialog>
                  </>
                )}
              </S.CardContainer>
            ) : (
              <S.CardContainer>
                <S.CardContainerDescription>
                  Cadastrar cartão de crédito
                </S.CardContainerDescription>
                <S.AddCardButton onClick={() => setOpenNewCardDialog(true)}>
                  <S.CardButtonDescription>
                    Cadastrar novo cartão de crédito
                  </S.CardButtonDescription>
                </S.AddCardButton>
                {openNewCardDialog && (
                  <>
                    <RegisterCardDialog
                      open={openNewCardDialog}
                      onClose={() => setOpenNewCardDialog(false)}
                      onSuccess={handleCardEncryption}

                    >
                    </RegisterCardDialog>
                  </>
                )}
                
              </S.CardContainer>
            )}
            <S.CardContainer>
                <S.CardContainerDescription>
                  Pagamento do plano pendente 
                </S.CardContainerDescription>
                <S.CardContainerDescriptionMinor>
                  Realize o pagamento do seu plano para continuar utilizando o Qi Sign.
                </S.CardContainerDescriptionMinor>
                {processingFirstTransaction ? 
                <S.AddCardButton gray onClick={() => {}}><CircularProgress style={{ width:"12px", height:"12px", margin:"auto", color:"white" }}/></S.AddCardButton>
                :
                <S.AddCardButton onClick={requestFirstTransaction}>
                  <S.CardButtonDescription>
                    Realizar o pagamento
                  </S.CardButtonDescription>
                </S.AddCardButton>
                }
              </S.CardContainer>
              {firstTransactionSuccess && (
                  <>
                    <Modal open={firstTransactionSuccess} onClose={setFirstTransactionSuccessModal}>
                      <Box sx={minorboxStyles}>
                        <S.MinorModalBoxContainer>
                          <CheckCircleOutlineIcon
                            style={{ color: "green", fontSize: 40 }}
                          ></CheckCircleOutlineIcon>
                          <S.InternalDialogContainerDescription>
                            Pagamento realizado com sucesso. 
                          </S.InternalDialogContainerDescription>
                          <S.MinorModalButtonContainer
                            onClick={() => closeFirstTransactionSuccessModal()}
                          >
                            <S.ModalButtonDescription>
                              Voltar
                            </S.ModalButtonDescription>
                          </S.MinorModalButtonContainer>
                        </S.MinorModalBoxContainer>
                      </Box>
                    </Modal>
                  </>
                )}
                {firstTransactionFailed && (
                  <>
                    <Modal open={firstTransactionFailed} onClose={closeFirstTransactionFailedModal}>
                      <Box sx={minorboxStyles}>
                        <S.MinorModalBoxContainer>
                          <ErrorOutlineOutlinedIcon
                            style={{ color: "red", fontSize: 40 }}
                          ></ErrorOutlineOutlinedIcon>
                          <S.InternalDialogContainerDescription>
                            Ocorreu um erro na requisição do pagamento do plano. 
                          </S.InternalDialogContainerDescription>
                          <S.MinorModalButtonContainer
                            onClick={() => closeFirstTransactionFailedModal()}
                          >
                            <S.ModalButtonDescription>
                              Voltar
                            </S.ModalButtonDescription>
                          </S.MinorModalButtonContainer>
                        </S.MinorModalBoxContainer>
                      </Box>
                    </Modal>
                  </>
                )}
          </S.ActivateAccountContainer>
        ): (
          <>
          <S.ActivateAccountContainer>
            <S.CardContainer style={{marginTop:"325px"}}>
                <S.CardContainerDescription>
                  A sua conta está ativa. Aproveite as funcionalidades do seu plano. 
                </S.CardContainerDescription>        
            </S.CardContainer>
          </S.ActivateAccountContainer>
          </>
        )}
      </>
    );
  }
};
