import FilterOption from "../../../components/FilterOption";
import FilterText from "../../../components/FilterText";
import FilterDate from "../../../components/FilterDate";
import BaseButton from "../../../components/BaseButton";
import { formatCPF } from "../../../utils/utils";
import React from "react";
import { frontEnvelopeStatusToBack } from "../../../utils/utils";

export const FilterGroup = (props) => {

    const { filters, setFilters, onFilter, filterLoading } = props

    return (
        <div className="filter-container">
            <FilterText 
                id={"name"}
                labelValue={"Nome do Assinante"}
                filters={filters}
                setFilters={setFilters}
                placeholder={"Nome do Assinante"}
            />
            <FilterText
                id={"document_number"}
                labelValue={"Documento do Assinante"}
                filters={filters}
                setFilters={setFilters}
                placeholder={"000.000.000-00"}
                formatter={(value)=>formatCPF(value)}
            />
            <FilterText 
                id={"external_id"}
                labelValue={"ID do Envelope"}
                filters={filters}
                setFilters={setFilters}
                placeholder={"ID do Envelope"}
            />
            <FilterText 
                id={"subject"}
                labelValue={"Nome do Envelope"}
                filters={filters}
                setFilters={setFilters}
                placeholder={"Nome do Envelope"}
            />
            <FilterOption
                id={"status"}
                labelValue={"Status do Envelope"}
                frontEnvelopeStatus={frontEnvelopeStatusToBack}
                filters={filters}
                setFilters={setFilters}
            />
            <FilterDate 
                id={"initial_date"}
                labelValue={"Data inicial"}
                filters={filters}
                setFilters={setFilters}
            />
            <FilterDate 
                id={"final_date"}
                labelValue={"Data final"}
                filters={filters}
                setFilters={setFilters}
            />
            <BaseButton
                label="Filtrar"
                onClick={() => onFilter()}
                className="filter__btn btn btn--animated btn--white"
                loading={filterLoading}
                isDisabled={false}
            />
        </div>
    );
}