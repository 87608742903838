
export const FailedAuthPage = (props) => {
    
    return (
        <div className="page-container">
            <p className="message__container message__container--centered">
                <span className="message__text">
                    Autenticação falhou
                </span>
            </p>
        </div>
    )
}