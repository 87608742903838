import React from "react";

export const DataField = (props) => {

    const { label, field } = props

    return (
        <p className="data-field">
            <span className="data-field__label">{`${label}: `}</span>
            <span className="data-field__value">{field}</span>
        </p>
    )
}