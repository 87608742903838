import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as S from "../../../containers/Report/styles";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: "center",
    marginBottom: "10px",
    float: "left",
  }
}));

function CSVLimitSizeDialog(props) {
  const classes = useStyles();
  useTheme();

  return (
    <Dialog open={props.open} onClose={props.onClose} keepMounted={false}>
      <DialogContent className={classes.dialogContent}>
        <S.DialogContainer>
          <S.InternalDialogContainer>
            <InfoOutlinedIcon
              style={{ width: "40px", height: "40px", color: "#0b1f82" }}
            />
          </S.InternalDialogContainer>
          <S.InternalDialogContainer>
            <S.InternalDialogContainerDescription>
              O número de análises retornadas na busca excede o limite de
              exportação.
            </S.InternalDialogContainerDescription>
          </S.InternalDialogContainer>
          <S.InternalDialogContainer>
            <S.InternalDialogContainerDescription>
              O arquivo gerado foi restrito para conter apenas as 100.000
              análises mais recentes.
            </S.InternalDialogContainerDescription>
          </S.InternalDialogContainer>
          <S.InternalDialogContainer style={{ marginTop: "35px" }}>
            <S.FailedDownloadButtonContainer onClick={() => props.onClose()}>
              <S.ModalButtonDescription>Voltar</S.ModalButtonDescription>
            </S.FailedDownloadButtonContainer>
          </S.InternalDialogContainer>
        </S.DialogContainer>
      </DialogContent>
    </Dialog>
  );
}

export default CSVLimitSizeDialog;
