import React, { useState, useCallback, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { DataField } from "../../components/DataField"
import { ColorInputField } from "../../components/ColorInputField"
import { WebhookInputField } from "../../components/WebhookInputField"
import axios from "axios"
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage"
import EditIcon from "@mui/icons-material/Edit"
import AuthContext from "../../context/auth-context"
import ErrorBoundary from "../AuxiliarPages/ErrorBoundary/ErrorBoundary"


export const SettingsPage = (props) => {
    
    const userRoles = useContext(AuthContext).user_data.roles
    
    const navigate = useNavigate()
    if (!userRoles.includes("read_company_settings")){
        navigate("/envelope-inbox")
    }

    const [fetchedData, setFetchedData] = useState(undefined)
    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)

    const [fontColor, setFontColor] = useState(undefined)
    const [backgroundColor, setBackgroundColor] = useState(undefined)
    const [buttonColor, setButtonColor] = useState(undefined)
    const [editMode, setEditMode] = useState(false)
    const [webhookUrl, setWebhookUrl] = useState(undefined)

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get(`/dash/sign/settings`)
                .then((response) => {
                    setFetchedData(response.data)
                    setFontColor((response.data.client_theme_settings||{}).font_color)
                    setWebhookUrl(response.data.webhook_url)
                    setBackgroundColor((response.data.client_theme_settings||{}).background_color)
                    setButtonColor((response.data.client_theme_settings||{}).button_color)
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, []);

    const updateSettingsData = useCallback(() => {
        if (fontColor&&backgroundColor&&buttonColor) {   
            const data = {
                "client_theme_settings": {
                    "font_color": fontColor,
                    "background_color": backgroundColor,
                    "button_color": buttonColor
                }
            }

            if (webhookUrl) {
                data['webhook_url'] = webhookUrl;
            }

            const timer = setTimeout(() => {
                axios.patch(`/dash/sign/settings`, data)
                .then((response) => {
                    setFontColor((response.data.client_theme_settings||{}).font_color)
                    setBackgroundColor((response.data.client_theme_settings||{}).background_color)
                    setButtonColor((response.data.client_theme_settings||{}).button_color)
                    setWebhookUrl(response.data.webhook_url)
                })
            }, 500);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [fontColor, backgroundColor, buttonColor, webhookUrl]);

    const onClickEdit = () => {
        setEditMode(true)
    }

    const onClickSave = () => {
        setEditMode(false)
        updateSettingsData()
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else {
        return (
            <div className="page-container">
                <header>
                    <h1>Configurações</h1>
                </header>
                <ul className="inbox">
                    <li className="inbox__header">
                        <div className="inbox__cell--row-container">
                            <div>Dados da Empresa:</div>
                            {userRoles.includes("update_company_settings")&&
                            <button
                                className={editMode?"btn btn--white btn--animated":"btn btn--white btn--no-border"}
                                onClick={()=>editMode?onClickSave():onClickEdit()}
                            >
                                <EditIcon style={{height:"1.5rem", width:"1.8rem"}}/>
                            </button>}
                        </div>
                    </li>
                </ul>
                <ul className="inbox">
                    <li className="inbox__body">
                        <ErrorBoundary>
                            <div className="inbox__cell--left">
                                <DataField
                                    label="Nome"
                                    field={fetchedData.name}
                                />
                                <ColorInputField
                                    label="Cor de Fonte"
                                    active={editMode}
                                    color={fontColor}
                                    setColor={setFontColor}
                                />
                                <ColorInputField
                                    label="Cor de Fundo"
                                    active={editMode}
                                    color={backgroundColor}
                                    setColor={setBackgroundColor}
                                />
                                <ColorInputField
                                    label="Cor de Fundo do Botão"
                                    active={editMode}
                                    color={buttonColor}
                                    setColor={setButtonColor}
                                />
                                <WebhookInputField
                                    label="Endereço de webhook"
                                    active={editMode}
                                    webhookUrl={webhookUrl}
                                    setWebhookUrl={setWebhookUrl}
                                />
                            </div>
                        </ErrorBoundary>
                        <div className="inbox__cell--center">
                            <p>Logo do App:</p>
                            <ErrorBoundary>
                                <img alt="Logo"  className="settings__logo" src={fetchedData.client_theme_settings.logo_url} />
                            </ErrorBoundary>
                        </div>
                        <div className="inbox__cell--center">
                            <p>Logo do Email:</p>
                            <ErrorBoundary>
                                <img alt="Logo" className="settings__logo" src={fetchedData.client_theme_settings.email_logo_url} />
                            </ErrorBoundary>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}