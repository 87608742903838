import React from "react";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            if (this.props.doNothing) return null
            return (
                <p className="message__container">
                    <span className="message__text">
                        Ocorreu um erro inesperado.
                    </span>
                    <span className="message__text">
                        Contate suporte.caas@qitech.com.br
                    </span>
                </p>
            );
        }
        return this.props.children;
    }
}
export default ErrorBoundary