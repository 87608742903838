import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage"
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage";
import AuthContext from "../../context/auth-context"
import UserInviteDialog from './UserInviteDialog/index.js'
import UserPermissionDialog from "./UserPermissionDialog "
import BaseButton from '../../components/BaseButton'
import * as S from './styles'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { DataField } from "../../components/DataField"
import ErrorBoundary from '../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Switch from "@material-ui/core/Switch";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { Pagination } from "../../components/Pagination"
import SearchBar from "../../components/SearchBar/index.js";

export const UserManagementPage = (props) => {

    const theme = createMuiTheme({
        palette: {
          secondary: {
            main: "#0b1f82",
          },
        },
    });
    
    const userRoles = useContext(AuthContext).user_data.roles
    
    const currentUsername = useContext(AuthContext).user_data.username

    const navigate = useNavigate()
    if (!userRoles.includes("admin_dashboard")){
        navigate("/envelope-inbox")
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const handleRefresh = () => {
        setRefreshPage(!refreshPage)
    }

    const handleUserPermissionDialog = (user) => {
        setUser(user)
        setOpenUserPermissionDialog(true)
    };

    const [disableAll, setDisableAll] = useState(false);

    const [openUserInviteDialog, setOpenUserInviteDialog] = useState(false)
    const [openUserPermissionDialog, setOpenUserPermissionDialog] = useState(false)
    const [user, setUser] = useState(undefined)

    const [filterContent, setFilterContent] = useState("");
    const [refresher, setRefresher] = useState(false);
    const [search, setSearch] = useState("");

    const handleSearch = (queryParameter) => {
        setSearch(queryParameter)
    };


    const handleStatusChange = async (checked, user) => {
        if (disableAll) return;
        setDisableAll(true);
    
        setSnackBarStatus({
            opened: true,
            severity: "info",
            message: "Espere um momento enquanto a sua requisição é processada",
        });
    
        try {
            const enabledData = { enabled: checked };
            await axios.put(`/dash/user/${user.username}`, enabledData);
            
            setSnackBarStatus({
                opened: true,
                severity: "success",
                message: checked ? "Usuário reativado com sucesso" : "Usuário desativado com sucesso",
            });
        } catch (error) {
            setSnackBarStatus({
                opened: true,
                severity: "error",
                message: checked ? "Não foi possível reativar este usuário" : "Não foi possível desativar este usuário",
            });
        } finally {
            setDisableAll(false);
            handleRefresh()
        }
    };

    const handleEmailInvite = (user) => {
        axios
          .put(`/dash/user/${user.username}/invite`)
          .then((response) => {
            setSnackBarStatus({
              opened: true,
              severity: "success",
              message: "Convite reenviado com sucesso.",
            });
          })
          .catch((error) => {
            setSnackBarStatus({
              opened: true,
              severity: "error",
              message: "Houve um problema ao reenviar o convite para este usuário",
            });
          });
          handleRefresh()
      };

    const [refreshPage, setRefreshPage] = useState(false)
    
    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const [usersData, setUsersData] = useState(undefined)
    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(1)
    const page_rows = 6;

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get(`/dash/users${search}`)
                .then((response) => {
                    setUsersData(response.data)
                    setMaxPage(Math.ceil(response.data.length/page_rows))
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [refreshPage, refresher, search]);

    if (!userRoles.includes("admin_dashboard")) {
        return (
          <S.Container>
            <NoAccessPage />
          </S.Container>
        );
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else {

        const indexOfLastItem = (currentPage + 1) * page_rows;
        const indexOfFirstItem = indexOfLastItem - page_rows;
        const currentPageUserItems = usersData.slice(indexOfFirstItem, indexOfLastItem);

        return (
            <S.Container>
                <S.SectionHeaderContainer>
                    <S.SectionTitle>
                        Gestão de Usuários
                    </S.SectionTitle>
                    <BaseButton
                        label="Convidar usuário"
                        onClick={() => setOpenUserInviteDialog(true)}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={false}
                    />
                </S.SectionHeaderContainer>
                <S.FilterContainer>
                    <SearchBar
                        label="Pesquisar usuários"
                        content={filterContent} 
                        setContent={setFilterContent} 
                        refresher={refresher} 
                        setRefresher={setRefresher}
                        onSearch={handleSearch}
                        variable={"search"}
                    />
                </S.FilterContainer>
                <ul className="inbox">
                    {usersData.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhum usuário encontrado
                        </span>
                    </p>}
                    {usersData.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left">Usuário</div>
                            <div className="inbox__cell--center">Dados cadastrais</div>
                            <div className="inbox__cell--right">Gerenciamento</div>
                        </li>}
                    {currentPageUserItems.map((user) => (
                        <li key={user.username} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <div>
                                        <DataField
                                            label="Nome do usuário"
                                            field={user.first_name}
                                        />
                                        <DataField
                                            label="Sobrenome do usuário"
                                            field={user.last_name}
                                        />
                                    </div>
                                </div>
                                <div className="inbox__cell--center">
                                    <DataField
                                        label="Documento"
                                        field={user.document_number}
                                    />
                                    <DataField
                                        label="Email"
                                        field={user.email}
                                    />
                                </div>
                                <div className="inbox__cell--right inbox__cell--row-container">
                                    {userRoles.includes("admin_dashboard") &&(
                                    <>
                                        <S.ManagementContainer>
                                            <button
                                                className="btn btn--animated btn--white"
                                                style={{width: "38px", height: "38px"}}
                                                onClick={() => handleUserPermissionDialog(user)}
                                            >
                                                <ManageAccountsOutlinedIcon
                                                    style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                    id={user.document_number}
                                                />
                                            </button>
                                            <S.ManagementDescription>
                                                Permissões
                                            </S.ManagementDescription>
                                        </S.ManagementContainer>
                                    </>)}
                                    {user.email_verified ? (
                                        <S.ManagementContainer>
                                            <ThemeProvider theme={theme} >
                                                <S.SwitchContainer>
                                                    <Switch
                                                        checked={user.enabled}
                                                        color="secondary"
                                                        onChange={(event) => {
                                                            if (user.username !== currentUsername) {
                                                                handleStatusChange(event.target.checked, user);
                                                            }
                                                        }}
                                                    />

                                                </S.SwitchContainer>
                                            </ThemeProvider>
                                            <S.ManagementDescription>
                                                {user.enabled? "Ativo": "Inativo"}
                                            </S.ManagementDescription>
                                        </S.ManagementContainer>
                                    ):
                                    (
                                        <S.ManagementContainer>
                                           <button
                                                className="btn btn--animated btn--white"
                                                style={{width: "38px", height: "38px"}}
                                                onClick={() => handleEmailInvite(user)}
                                            >
                                                <ForwardToInboxOutlinedIcon
                                                    style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                />
                                            </button>
                                            <S.ManagementDescription>
                                                 Reenviar
                                            </S.ManagementDescription>
                                        </S.ManagementContainer>
                                    )

                                    }
                                    
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                {openUserInviteDialog &&
                    <UserInviteDialog
                        open={openUserInviteDialog}
                        setOpen={setOpenUserInviteDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                    />}
                {openUserPermissionDialog && 
                    <UserPermissionDialog
                        open={openUserPermissionDialog}
                        setOpen={setOpenUserPermissionDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        user={user}
                    />
                    
                }
                <Snackbar
                        open={snackBarStatus.opened}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackBar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackBar}
                        severity={snackBarStatus["severity"]}
                    >
                        {snackBarStatus.message}
                    </MuiAlert>
                </Snackbar>
                <Pagination
                    currentPage={currentPage}
                    maxPage={maxPage}
                    setCurrentPage={setCurrentPage}
                    maxVisiblePages={7}
                />
            </S.Container>
        )
    }
}