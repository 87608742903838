import Active from "@mui/icons-material/CheckBox";
import Pending from "@mui/icons-material/IndeterminateCheckBox";
import Expired from "../assets/images/expired.png";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const colorGreen = '#73C580'

const colorRed ='#D91A1A'

export const formatISOZuluDate = (isoZuluDate) => {
  const date = new Date(isoZuluDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = date.toLocaleString("pt-BR", options);
  return formattedDate;
};

export const formatDateToISOZulu = (date, type) => {
  const days = date.slice(0, 2);
  const months = date.slice(3, 5);
  const years = date.slice(6, 10);
  let today = new Date(`${years}-${months}-${days}T00:00:00`);
  let tomorrow = new Date(today.getTime() + 86400000);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const todayISOZuluDate = today
    .toISOString("pt-BR", options)
    .replace(".000", "");
  const tomorrowISOZuluDate = tomorrow
    .toISOString("pt-BR", options)
    .replace(".000", "");
  if (type === "final_date") {
    return tomorrowISOZuluDate;
  } else if (type === "birthdate" || type === "expiration_date") {
    return `${years}-${months}-${days}`;
  } else {
    return todayISOZuluDate;
  }
};

export const formatPhoneToFront = (Phone) => {
  if (Phone) {
    const areaCode = Phone.area_code || "";
    const number = Phone.number || "";
    return `${areaCode}${number}`;
  }
  return "";
}

export const formatBirthdateToFront = (backendBirthdate) => {
  if (backendBirthdate) {
    const numbers = justNumbers(backendBirthdate);
    const days = numbers.slice(6, 8);
    const months = numbers.slice(4, 6);
    const years = numbers.slice(0, 4);
    return `${days}/${months}/${years}`;
  }
  return "";
};

export const isValidDate = (dateString) => {
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1; // Months are zero-based
  var year = parseInt(parts[2], 10);

  var date = new Date(year, month, day);

  return (
    date.getDate() === day &&
    date.getMonth() === month &&
    date.getFullYear() === year &&
    year >= 1900 &&
    year <= 2100
  );
};

export function isValidCPF(cpf) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  var sum = 0;
  var remainder;
  for (var count = 1; count <= 9; count++)
    sum = sum + parseInt(cpf.substring(count - 1, count)) * (11 - count);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (count = 1; count <= 10; count++)
    sum = sum + parseInt(cpf.substring(count - 1, count)) * (12 - count);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}

export const isDateOlderThanToday = (dateString) => {
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1; // Months are zero-based
  var year = parseInt(parts[2], 10);

  var date = new Date(year, month, day);
  var today = new Date();

  if (date.toDateString() === today.toDateString() || date > today) {
    return false;
  } else {
    return true;
  }
};

export const formatDate = (value) => {
  let v = value.replace(/\D/g, "").slice(0, 8);
  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
  } else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`;
  }
  return v;
};

export const formatCPF = (value) => {
  return value
    .slice(0, 14)
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2");
};

export const formatDocumentNumberWithoutPontuaction = (document_number) => {
  return document_number.replace(/\D/g, '');
}

export const justNumbers = (value) => {
  return value.replace(/\D/g, "");
};

export const formatPhoneWithoutIntCode = (value) => {
  value = justNumbers(value);
  let v = String(value.substring(0, 11));
  if (v.length === 0) {
    return "";
  }
  if (v.length < 3) {
    return "(" + v.substring(0, v.length);
  } else if (v.length < 8) {
    return "(" + v.substring(0, 2) + ") " + v.substring(2, v.length);
  } else {
    return (
      "(" +
      v.substring(0, 2) +
      ") " +
      v.substring(2, 7) +
      "-" +
      v.substring(7, v.length)
    );
  }
};

export const formatPhone = (value) => {
  value = justNumbers(value);
  const stringValue = String(value.substring(0, 11));
  if (stringValue.length === 0) {
    return "";
  }
  if (stringValue.length < 3) {
    return "(" + stringValue;
  }
  if (stringValue.length < 8) {
    return (
      "(" +
      stringValue.substring(0, 2) +
      ") " +
      stringValue.substring(2, stringValue.length)
    );
  }

  return (
    "(" +
    stringValue.substring(0, 2) +
    ") " +
    stringValue.substring(2, 7) +
    "-" +
    stringValue.substring(7, stringValue.length)
  );
};

export const isPhoneWithoutIntCode = (value) => {
  if (value === undefined) {
    return true;
  } else {
    value = justNumbers(value);
    if (value.length < 11) return false;
    else return true;
  }
};

export const isPhone = (value) => {
  value = justNumbers(value);
  if (value.length === 0) return true;
  else if (value.length < 11) return false;
  else return true;
};

export const isEmail = (value) => value.includes("@");

export const frontEnvelopeStatusToBack = {
  created: {
    text: "Envelope criado",
    backStatus: ["created", "submitted"],
  },
  waiting: {
    text: "Aguardando assinaturas",
    backStatus: ["on_signature"],
  },
  dossiers_in_progress: {
    text: "Gerando documentos",
    backStatus: [
      "ready_for_dossier_generation",
      "dossier_generation_in_progress",
      "ready_for_signer_dossier_generation",
      "signer_dossier_generation_in_progress",
    ],
  },
  signed: {
    text: "Envelope assinado",
    backStatus: ["completed"],
  },
  expired: {
    text: "Envelope expirado",
    backStatus: ["expired"],
  },
  canceled: {
    text: "Envelope cancelado",
    backStatus: ["canceled"],
  },
};

export const frontEnvelopeStatusToStyle = {
  created: {
    text: "Envelope criado",
    backgroundColor: "background-light-gray",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  waiting: {
    text: "Aguardando assinaturas",
    backgroundColor: "background-light-yellow",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  dossiers_in_progress: {
    text: "Gerando documentos",
    backgroundColor: "background-dark-yellow",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  signed: {
    text: "Envelope assinado",
    backgroundColor: "background-light-green",
    icon: <Active style={{ height: "18px", width: "18px" }} />,
  },
  expired: {
    text: "Envelope expirado",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
  canceled: {
    text: "Envelope cancelado",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
};

export const backEnvelopeStatusToFront = {
  created: "created",
  submitted: "created",
  on_signature: "waiting",
  ready_for_dossier_generation: "dossiers_in_progress",
  dossier_generation_in_progress: "dossiers_in_progress",
  ready_for_signer_dossier_generation: "dossiers_in_progress",
  signer_dossier_generation_in_progress: "dossiers_in_progress",
  completed: "signed",
  expired: "expired",
  canceled: "canceled",
};

export const backSignerBatchStatusToFront = {
  created: "created",
  processing: "processing",
  completed: "completed",
  canceled: "canceled",
  expired: "expired"
};

export const frontSignerBatchStatusToStyle = {
  created: {
    text: "Assinatura criada",
    backgroundColor: "background-light-gray",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  processing: {
    text: "Em processamento",
    backgroundColor: "background-light-yellow",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  completed: {
    text: "Assinatura finalizada",
    backgroundColor: "background-light-green",
    icon: <Active style={{ height: "18px", width: "18px" }} />,
  },
  expired: {
    text: "Assinatura expirada",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
  canceled: {
    text: "Assinatura cancelada",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
};


export const frontSignerStatusToStyle = {
  created: {
    text: "Assinante criado",
    backgroundColor: "background-light-gray",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  waiting: {
    text: "Aguardando assinatura",
    backgroundColor: "background-light-yellow",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  signed: {
    text: "Assinatura realizada",
    backgroundColor: "background-light-green",
    icon: <Active style={{ height: "18px", width: "18px" }} />,
  },
  analyzing: {
    text: "Análises em andamento",
    backgroundColor: "background-dark-yellow",
    icon: <Pending style={{ height: "18px", width: "18px" }} />,
  },
  expired: {
    text: "Assinatura expirada",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
  canceled: {
    text: "Assinatura cancelada",
    backgroundColor: "background-light-red",
    icon: (
      <img
        style={{ height: "18px", width: "18px" }}
        src={Expired}
        alt="Expired"
      />
    ),
  },
};

export const backSignerStatusToFront = {
  created: "created",
  submitted: "created",
  on_signature: "waiting",
  signed: "analyzing",
  sending_sign_receipt: "analyzing",
  receipt_sent: "analyzing",
  analyzing: "analyzing",
  analyzed: "signed",
  completed: "signed",
  expired: "expired",
  canceled: "canceled",
};

export const formatDateReports = (inputDate) => {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const getConfirmationIcon = (status) => {
  if (status) {
    return (
      <CheckIcon style={{ color: "#73C580", width: "40px", height: "40px" }} />
    );
  } else {
    return (
      <ClearIcon style={{ color: "#D91A1A", width: "40px", height: "40px" }} />
    );
  }
};

export const getfilterReportToFront = (filter) => {
  if (filter === "initial_date") {
    return "Data de ínicio";
  } else if (filter === "final_date") {
    return "Data de término";
  } else if (filter === "csv_rows_limit") {
    return "Quantidade de linhas";
  } else if (filter === "signer_status") {
    return "Status do assinante";
  } else if (filter === "envelope_status") {
    return "Status do envelope";
  } else if (filter === "document_number") {
    return "Documento do assinante";
  } else if (filter === "external_id") {
    return "ID do envelope";
  } else {
    return filter;
  }
};

export const getfilterDataToFront = (filter, value) => {
  if (filter === "initial_date" || filter === "final_date") {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString("pt-BR");
    return formattedDate;
  } else if (filter === "signer_status" || filter === "envelope_status") {
    return translateStatusToFront(value)
  } else {
    return value
  }
};


export const translateStatusToFront = (value) => {
  if (value === "canceled") {
    return "Cancelado";
  } else if (value === "created") {
    return "Criado";
  } else if (value === "submitted") {
    return "Submetido";
  } else if (value === "on_signature") {
    return "Em assinatura";
  } else if (value === "signed") {
    return "Assinado";
  } else if (value === "completed") {
    return "Completo";
  } else if (value === "expired") {
    return "Expirado";
  } else {
    return value
  }
  
};

//Check if nested key exists inside object
export function checkNested (obj /*, level1, level2, ... levelN*/) {
  if (obj == null) return false
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
      }
      obj = obj[args[i]];
  }
  return true;
}

//Get field check icon
export const getConfirmationIconField = (success) => {
  if (success){
      return (<CheckIcon className="fraudStatusIcon" style={{color: colorGreen, width:"20px", height:"20px", margin:"20px 20px 20px 0"}}/>)
  }
  else{
      return (<ClearIcon className="fraudStatusIcon" style={{color: colorRed, width:"20px", height:"20px", margin:"20px 20px 20px 0"}}/>)
  }
}

export const getValidationIcon = (validation, isNameField) => {
  if (isNameField && validation === "") {
    return null;
  } else {
    return getConfirmationIconField(validation);
  }
};
