

export const BaseAvatar = (props) => {
    
    const { value } = props;
    
    return (
        <div className="avatar-container">
            <div className="avatar">
                {value}
            </div>
        </div>
    )
}