import React from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

function BaseButton (props) {
    
    const { onClick, label, isDisabled, className } = props

    return(
        <button
            className={className}
            onClick={(isDisabled||props.loading===true)? null : onClick} 
            disabled={isDisabled}
        >
            {props.loading===true?
            <CircularProgress style={{color: "#2d2d2d"}} size={"1.3rem"}/>
            :
            props.children?
            <div className="btn__content--gap">
                {props.children}
                <div>{label}</div>
            </div>
            :
            <div >{label}</div>}
        </button>
    )
}

export default BaseButton
