import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => navigate(`/envelope-inbox`));

  return (
    <div className="page-container">
      <p className="message__container message__container--centered">
        <span className="message__text">Loading ...</span>
        <CircularProgress
          style={{
            color: "#525252",
            width: "20px",
            height: "20px",
            margin: "10px auto",
          }}
        />
      </p>
    </div>
  );
};
