import React from 'react';

function formatDate(isoZuluDate) {
    const date = new Date(isoZuluDate)
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const localDate = date.toLocaleString('pt-BR', options)
    const monthNames = ['Jan', 'Fev', 'Mar','Abr', 'Mai', 'Jun', 'Jul','Ago', 'Set', 'Out','Nov', 'Dez'];
    const day = localDate.slice(0,2)
    const month = monthNames[Number(localDate.slice(3,5))-1]
    const year = localDate.slice(6,10)
    const time = localDate.slice(12,20)

    return { day, month, year, time };
}

export const DatetimeBlock = (props) => {

    const { dateString } = props
    const { day, month, year, time } = formatDate(dateString);
    return (
        <div className="datetime-block">
            <p id="day">{day}</p>
            <p>{month} {year}</p>
            <p id="time">{time}</p>
        </div>
    );
}