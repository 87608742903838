import React, { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "../../context/auth-context";
import axios from "axios";
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage";
import * as S from "./styles";
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage";
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage";
import { Pagination } from "@mui/material";
import ReportList from "../Report/ReportList/ReportList";
import qs from "qs";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Filter from "../Report/Filter/Filter";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const ReportInbox = () => {
  const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    height: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const minorboxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const userRoles = useContext(AuthContext).user_data.roles;

  const [reportListTemplates, setReportListTemplates] = useState(undefined);

  const [reportTemplateFilters, setReportTemplateFilters] = useState(undefined);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openSuccessModal, setSuccessModal] = useState(false);
  const [openFailedModal, setFailedModal] = useState(false);

  const [error, setError] = useState({
    hasError: false,
    statusCode: 200,
  });

  const [loading, setLoading] = useState(true);
  const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [config, setConfig] = useState({
    params: {
      page_number: 0,
      page_rows: 25,
    },
  });

  const [reportTemplate, setReportTemplate] = useState(undefined);
  const handleOpenModal = (index) => {
    setOpenFilterModal(true);
    setReportTemplate(reportListTemplates[index]);
  };

  const closeSuccessModal = () => {
    setSuccessModal(false);
    window.location.reload();
  };

  const closeFailedModal = () => {
    setFailedModal(false);
    window.location.reload();
  };

  const closeModal = () => {
    setOpenFilterModal(false);
    setReportTemplate(undefined);
  };

  const handleReportTemplateChangePage = (event, page) => {
    if (currentTemplatePage === page) {
      return;
    }
    setCurrentTemplatePage(page);
  };

  const handleReportSubmit = useCallback((reportTemplateFilters, report_template_key) => {

    if (!reportTemplateFilters){
      setReportTemplateSubmission(false);
      return
    }

    let payload = {
      report_template_key: report_template_key,
    };

    let filters = {};
    Object.keys(reportTemplateFilters.filters).forEach((key, index) => {
      filters[key] = reportTemplateFilters.filters[key];
    });

    payload["filters"] = filters;

    const timer = setTimeout(() => {
      axios
        .post("/dash/report/report", payload)
        .then((response) => {
          setSuccessModal(true);
          setReportTemplateSubmission(false);
        })
        .catch((error) => {
          if ([404].includes((error.response || {}).status)) {
            setError({
              has_error: true,
              status: error.response.status,
            });
            setFailedModal(true);
          } else {
            setError({
              has_error: true,
              status: 500,
            });
            setFailedModal(true);
          }
        })
        .finally(() => {
          closeModal();
        });
      return () => {
        clearTimeout(timer);
      };
    }, 300);
  }, []);

  const requestReportTemplates = useCallback(() => {
    const timer = setTimeout(() => {
      const queryString = qs
        .stringify(config, { arrayFormat: "repeat", encode: false })
        .replaceAll("params", "")
        .replaceAll("[", "")
        .replaceAll("]", "");
      axios
        .get(`/dash/report/report_templates?${queryString}`)
        .then((response) => {
          setReportListTemplates(response.data[0].data.report_templates);
          setMaxPage(response.data[0].number_of_pages);
          setLoading(false);
        })
        .catch((error) => {
          if ([404].includes((error.response || {}).status)) {
            setError({
              has_error: true,
              status: error.response.status,
            });
          } else {
            setError({
              has_error: true,
              status: 500,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [config]);

  const [reportTemplateSubmission, setReportTemplateSubmission] =
    useState(false);

  useEffect(() => {
    let newConfig;
    newConfig = config;
    newConfig.params.page_number = currentTemplatePage - 1;
    setConfig(newConfig);
    requestReportTemplates();
  }, [currentTemplatePage, config, requestReportTemplates]);

  useEffect(() => {
    if (reportTemplateSubmission === true) {
      handleReportSubmit(reportTemplateFilters, reportTemplate.report_template_key);
    }
  }, [
    reportTemplateFilters,
    reportTemplateSubmission,
    handleReportSubmit,
    reportTemplate
  ]);

  if (!userRoles.includes("read_reports")) {
    return (
      <S.ReportContainer>
        <NoAccessPage />
      </S.ReportContainer>
    );
  }
  if (loading) {
    return (
      <S.ReportContainer>
        <LoadingPage />
      </S.ReportContainer>
    );
  } else if (error.hasError) {
    return (
      <S.ReportContainer>
        <ErrorPage />
      </S.ReportContainer>
    );
  } else {
    return (
      <S.ReportContainer>
        <S.ReportSectionTitle>
          <S.ReportTemplateSectionTitle>
            Relatórios
          </S.ReportTemplateSectionTitle>
        </S.ReportSectionTitle>
        {userRoles.includes("create_reports") && (
          <S.ReportTemplateContainer>
            <S.ReportTemplateDescription style={{ marginBottom: "20px" }}>
              Solicitar relatório
            </S.ReportTemplateDescription>
            {reportListTemplates.length !== 0 ? (
              <S.ReportTemplateItemsContainer>
                {reportListTemplates.map((template, index) => (
                  <S.ReportTemplateButton
                    key={template.report_template_key}
                    id={template.report_template_key}
                    onClick={() => handleOpenModal(index)}
                  >
                    {template.name}
                  </S.ReportTemplateButton>
                ))}
                {openFilterModal && reportTemplate && (
                  <>
                    <Modal open={openFilterModal} onClose={closeModal}>
                      <Box sx={boxStyles}>
                        <S.ModalBoxContainer>
                          <S.FilterListContainer>
                            <S.FilterListHeader>
                              Solicitar relatório
                            </S.FilterListHeader>
                            <Filter
                              setReportTemplateFilters={
                                setReportTemplateFilters
                              }
                              reportFilterTypes={reportTemplate.filters}
                            ></Filter>
                          </S.FilterListContainer>
                          <S.FilterListContainer></S.FilterListContainer>
                          <S.ModalButtonContainer
                            onClick={() => setReportTemplateSubmission(true)}
                          >
                            <S.ModalButtonDescription>
                              Enviar
                            </S.ModalButtonDescription>
                          </S.ModalButtonContainer>
                        </S.ModalBoxContainer>
                      </Box>
                    </Modal>
                  </>
                )}
                {openSuccessModal && (
                  <>
                    <Modal open={openSuccessModal} onClose={closeSuccessModal}>
                      <Box sx={minorboxStyles}>
                        <S.MinorModalBoxContainer>
                          <CheckCircleOutlineIcon
                            style={{ color: "green", fontSize: 40 }}
                          ></CheckCircleOutlineIcon>
                          <S.InternalDialogContainerDescription>
                            Solicitação realizada com sucesso. Aguarde o
                            processamento do seu relatório.
                          </S.InternalDialogContainerDescription>
                          <S.MinorModalButtonContainer
                            onClick={() => closeSuccessModal()}
                          >
                            <S.ModalButtonDescription>
                              Voltar
                            </S.ModalButtonDescription>
                          </S.MinorModalButtonContainer>
                        </S.MinorModalBoxContainer>
                      </Box>
                    </Modal>
                  </>
                )}
                {openFailedModal && (
                  <>
                    <Modal open={openFailedModal} onClose={closeFailedModal}>
                      <Box sx={minorboxStyles}>
                        <S.MinorModalBoxContainer>
                          <ErrorOutlineOutlinedIcon
                            style={{ color: "red", fontSize: 40 }}
                          ></ErrorOutlineOutlinedIcon>
                          <S.InternalDialogContainerDescription>
                            Ocorreu um erro na solicitação do relatório. Por
                            favor, entre em contato com nosso suporte através do
                            email suporte.caas@qitech.com.br informando esta
                            mensagem.
                          </S.InternalDialogContainerDescription>
                          <S.MinorModalButtonContainer
                            onClick={() => closeFailedModal()}
                          >
                            <S.ModalButtonDescription>
                              Voltar
                            </S.ModalButtonDescription>
                          </S.MinorModalButtonContainer>
                        </S.MinorModalBoxContainer>
                      </Box>
                    </Modal>
                  </>
                )}
              </S.ReportTemplateItemsContainer>
            ) : (
              <S.ReportNoContentDescription>
                Não há relatórios disponíveis
              </S.ReportNoContentDescription>
            )}
            <Pagination
              style={{ marginTop: "15px" }}
              page={currentTemplatePage}
              count={maxPage}
              onChange={(event, page) =>
                handleReportTemplateChangePage(event, page)
              }
            />
          </S.ReportTemplateContainer>
        )}
        <ReportList></ReportList>
      </S.ReportContainer>
    );
  }
};
