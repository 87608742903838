import React, { useState, useEffect } from 'react'
import { Dialog, Button, CircularProgress } from '@mui/material'
import axios from 'axios'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as S from '../styles'
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"


function UserPermissionDialog(props) {
    const { open, setOpen, setSnackBarController, handleRefresh, user } = props

    const [loading, setLoading] = useState(false)

    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const primaryColor = "#0b1f82"
    
    useEffect(() => {
        if (user.roles.includes('create_envelopes')) {setCreateEnvelopes(true)}
        if (user.roles.includes('cancel_envelope')) {setCancelEnvelope(true)}
        if (user.roles.includes('list_envelopes')) {setListEnvelopes(true)}
        if (user.roles.includes('load_envelope_report')) {setLoadEnvelopeReport(true)}
        if (user.roles.includes('read_envelope')) {setReadEnvelope(true)}
        if (user.roles.includes('create_reports')) {setCreateReports(true)}
        if (user.roles.includes('read_envelope_reports')) {setReadEnvelopeReports(true)}
        if (user.roles.includes('read_reports')) {setReadReports(true)}
        if (user.roles.includes('read_company_settings')) {setReadCompanySettings(true)}
        if (user.roles.includes('update_company_settings')) {setUpdateCompanySettings(true)}
        if (user.roles.includes('update_signer_data')) {setUpdateSignerData(true)}
        if (user.roles.includes('renotify_signer')) {setRenotifySigner(true)}
        if (user.roles.includes('admin_dashboard')) {setAdminDashboard(true)}
        
    }, [user.roles]);

    const [createEnvelopes, setCreateEnvelopes] = useState(false);
    const [cancelEnvelope, setCancelEnvelope] = useState(false);
    const [listEnvelopes, setListEnvelopes] = useState(false);
    const [loadEnvelopeReport, setLoadEnvelopeReport] = useState(false);
    const [readEnvelope, setReadEnvelope] = useState(false);
    const [createReports, setCreateReports] = useState(false);
    const [readEnvelopeReports, setReadEnvelopeReports] = useState(false);
    const [readReports, setReadReports] = useState(false);
    const [readCompanySettings, setReadCompanySettings] = useState(false);
    const [updateCompanySettings, setUpdateCompanySettings] = useState(false);
    const [updateSignerData, setUpdateSignerData] = useState(false);
    const [renotifySigner, setRenotifySigner] = useState(false);
    const [adminDashboard, setAdminDashboard] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const updateRoleLists = (currentRoles, newRoleStatus, addedRolesData, deletedRolesData) => {
        const roles = [
            'create_envelopes',
            'cancel_envelope',
            'read_envelope',
            'list_envelopes',
            'load_envelope_report',
            'create_reports',
            'read_envelope_reports',
            'read_reports',
            'read_company_settings',
            'update_company_settings',
            'update_signer_data',
            'renotify_signer',
            'admin_dashboard',
        ];
    
        roles.forEach(role => {
            if (currentRoles.includes(role) && !newRoleStatus[role]) {
                deletedRolesData.roles.push(role);
            } else if (!currentRoles.includes(role) && newRoleStatus[role]) {
                addedRolesData.roles.push(role);
            }
        });
    };
    
    const handleSubmit = async () => {
        setLoading(true);
    
        const addedRolesData = { roles: [] }
        const deletedRolesData = { roles: [] }
    
        const newRoleStatus = {
            create_envelopes: createEnvelopes,
            cancel_envelope: cancelEnvelope,
            read_envelope: readEnvelope,
            list_envelopes: listEnvelopes,
            load_envelope_report: loadEnvelopeReport,
            create_reports: createReports,
            read_envelope_reports: readEnvelopeReports,
            read_reports: readReports,
            read_company_settings: readCompanySettings,
            update_company_settings: updateCompanySettings,
            update_signer_data: updateSignerData,
            renotify_signer: renotifySigner,
            admin_dashboard: adminDashboard,
        };
    
        updateRoleLists(user.roles, newRoleStatus, addedRolesData, deletedRolesData);
    
        try {
            if (deletedRolesData.roles.length !== 0) {
                await axios.delete(`/dash/user/${user.username}/roles`, {data: deletedRolesData});
            }
    
            if (addedRolesData.roles.length !== 0) {
                await axios.post(`/dash/user/${user.username}/roles`, addedRolesData);
            }
    
            setSnackBarController({
                opened: true,
                severity: "success",
                message: "Permissões alteradas com sucesso"
            });
            setOpen(false);
            handleRefresh();
        } catch (error) {
            let errorMessage;
            if (error.response && error.response.status === 400) {
                errorMessage = "Parâmetros inválidos ao alterar as permissões deste usuário";
            } else {
                errorMessage = "Houve um problema ao alterar as permissões deste usuário";
            }
            setSnackBarStatus({
                opened: true,
                severity: "error",
                message: errorMessage
            });
        } finally {
            setLoading(false);
        }
    };    

    if (loading) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <div style={{ width: '400px', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </div>
            </Dialog>
        )
    }

    return (
        <>
            <Dialog open={open}>
                <div style={{ width: '400px', padding: '2rem' }}>
                    <IconButton
                        aria-label="Close"
                        onClick={() => {
                            setOpen(false)
                        }}
                        style={{ float: "right"}}
                    >
                    <CloseIcon />
                    </IconButton>
                    <h2 style={{ marginBottom: '1.5rem' }}>Alterar Permissões</h2>
                    <h2 style={{ marginBottom: '1.5rem' }}>Administrador</h2>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={adminDashboard} onChange={() => setAdminDashboard(!adminDashboard)}></S.Input>
                        <S.PermissionDescription>Adicionar esse usuário como administrador</S.PermissionDescription>
                    </S.PermissionContainer>
                    <h2 style={{ marginBottom: '1.5rem', marginTop: '1.5rem'}}>Permissões</h2>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={createEnvelopes} onChange={() => setCreateEnvelopes(!createEnvelopes)}></S.Input>
                        <S.PermissionDescription>Criar novo envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readEnvelope} onChange={() => setReadEnvelope(!readEnvelope)}></S.Input>
                        <S.PermissionDescription>Leitura das informações do envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={cancelEnvelope} onChange={() => setCancelEnvelope(!cancelEnvelope)}></S.Input>
                        <S.PermissionDescription>Cancelar envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={listEnvelopes} onChange={() => setListEnvelopes(!listEnvelopes)}></S.Input>
                        <S.PermissionDescription>Visualizar a listagem de envelopes</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={loadEnvelopeReport} onChange={() => setLoadEnvelopeReport(!loadEnvelopeReport)}></S.Input>
                        <S.PermissionDescription>Visualizar dossiê de análise do envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={createReports} onChange={() => setCreateReports(!createReports)}></S.Input>
                        <S.PermissionDescription>Criar relatório</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readReports} onChange={() => setReadReports(!readReports)}></S.Input>
                        <S.PermissionDescription>Visualizar a listagem de relatórios</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readEnvelopeReports} onChange={() => setReadEnvelopeReports(!readEnvelopeReports)}></S.Input>
                        <S.PermissionDescription>Visualizar relatórios de envelopes</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readCompanySettings} onChange={() => setReadCompanySettings(!readCompanySettings)}></S.Input>
                        <S.PermissionDescription>Visualizar configurações</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={updateCompanySettings} onChange={() => setUpdateCompanySettings(!updateCompanySettings)}></S.Input>
                        <S.PermissionDescription>Editar configurações</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={updateSignerData} onChange={() => setUpdateSignerData(!updateSignerData)}></S.Input>
                        <S.PermissionDescription>Editar informações do assinante</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={renotifySigner} onChange={() => setRenotifySigner(!renotifySigner)}></S.Input>
                        <S.PermissionDescription>Realizar renotificação de assinatura</S.PermissionDescription>
                    </S.PermissionContainer>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSubmit} 
                            style={{ width: '100%', height: '32px', fontSize: '12px', margin: '2rem 0', backgroundColor: primaryColor, color:'white' }}
                        >
                            Alterar Permissões
                        </Button>
                    </div>
                </div> 
            </Dialog>
            <Snackbar
                open={snackBarStatus.opened}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackBar}
                    severity={snackBarStatus["severity"]}
                >
                    {snackBarStatus.message}
                </MuiAlert>
            </Snackbar>
        </>
    )
};
export default UserPermissionDialog