import React, { useState } from 'react'
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, Button, CircularProgress } from '@mui/material'
import { LabelInput } from '../../../components/LabelInput'
import { isEmail, formatCPF, isValidCPF } from '../../../utils/utils'
import axios from 'axios'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as S from '../styles'



function UserInviteDialog(props) {
    const { open, setOpen, setSnackBarController, handleRefresh } = props

    const [loading, setLoading] = useState(false)

    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const [createEnvelopes, setCreateEnvelopes] = useState(false);
    const [cancelEnvelope, setCancelEnvelope] = useState(false);
    const [listEnvelopes, setListEnvelopes] = useState(false);
    const [loadEnvelopeReport, setLoadEnvelopeReport] = useState(false);
    const [readEnvelope, setReadEnvelope] = useState(false);

    const [createReports, setCreateReports] = useState(false);
    const [readEnvelopeReports, setReadEnvelopeReports] = useState(false);
    const [readReports, setReadReports] = useState(false);
    
    const [readCompanySettings, setReadCompanySettings] = useState(false);
    const [updateCompanySettings, setUpdateCompanySettings] = useState(false);

    const [updateSignerData, setUpdateSignerData] = useState(false);
    const [renotifySigner, setRenotifySigner] = useState(false);

    const [adminDashboard, setAdminDashboard] = useState(false);
    

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const primaryColor = "#0b1f82"

    const [errors, setErrors] = useState({
        first_name: "",
        last_name: "",
        document_number: "",
        email: "",
    })

    const [touched, setTouched] = useState({
        first_name: false,
        last_name: false,
        document_number: false,
        email: false
    })

    const errorMessages = {
        email: "Email inválido",
        first_name: "Nome é obrigatório",
        last_name: "Sobrenome é obrigatório",
        document_number: "CPF inválido",
    }

    const handleBlur = (id, value) => {
        const error = !validateInput[id](value)
        setErrors({
            ...errors,
            [id]: error
        })
        setValues({
            ...values,
            [id]: value
        })
        setTouched((prevTouched) => ({
            ...prevTouched,
            [id]: true,
        }))
    }

    const [values, setValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        document_number: "",
    })

    const resetForm = () => {
        setValues({
            first_name: "",
            last_name: "",
            document_number: "",
            email: "",    
        })
        setErrors({
            first_name: "",
            last_name: "",
            document_number: "",
            email: "",    
        })
    }

    const validateForm = () => {
        const allValues = {
            ...values
        }

        const allTouched = {
            ...touched,
            first_name: true,
            last_name: true,
            document_number: true,
            email: true
        }

        setTouched(allTouched)

        const areFieldsValid = Object.keys(allValues).every((key) => {
            return validateInput[key](values[key])
        })

        return areFieldsValid
    }
    
    const validateInput = {
        email: (value) => isEmail(value),
        first_name: (value) => value !== "",
        last_name: (value) => value !== "",
        document_number: (value) => value !== "",
    }

    const handleInputChange = (field, value) => {
        const updatedValues = { ...values }
        updatedValues[field] = value
        setValues(updatedValues)
    }

    const addRoles = (inviteUserData) => {
        if (createEnvelopes) {inviteUserData.roles.push("create_envelopes")}
        if (cancelEnvelope) {inviteUserData.roles.push("cancel_envelope")}
        if (listEnvelopes) {inviteUserData.roles.push("list_envelopes")}
        if (loadEnvelopeReport) {inviteUserData.roles.push("load_envelope_report")}
        if (createReports) {inviteUserData.roles.push("create_reports")}
        if (readEnvelopeReports) {inviteUserData.roles.push("read_envelope_reports")}
        if (readReports) {inviteUserData.roles.push("read_reports")}
        if (readCompanySettings) {inviteUserData.roles.push("read_company_settings")}
        if (updateCompanySettings) {inviteUserData.roles.push("update_company_settings")}
        if (updateSignerData) {inviteUserData.roles.push("update_signer_data")}
        if (renotifySigner) {inviteUserData.roles.push("renotify_signer")}
        if (adminDashboard) {inviteUserData.roles.push("admin_dashboard")}
    }

    const handleSubmit = async () => {
        const valid = validateForm()
        if (!valid) {
            return
        }

        setLoading(true)

        const inviteUserData = {
            first_name: values.first_name,
            last_name: values.last_name,
            document_number: values.document_number,
            email: (values.email).trim(),
            roles: []
        }

        addRoles(inviteUserData)

        axios.post("/dash/user", inviteUserData)
        .then((response) => {    
            setSnackBarController({
                opened:true, 
                severity:"success", 
                message: "Usuário convidado com sucesso"
            })
            resetForm()
            setOpen(false)
            handleRefresh()
        })
        .catch((error) => {
            let errorMessage
            if ((error.response || {}).status === 409) {
                errorMessage = "E-mail já cadastrado"
            } else if ((error.response || {}).status === 400) {
                errorMessage = "Parâmetros inválidos ao convidar este usuário"
            } else {
                errorMessage = "Houve um problema ao convidar este usuário"
            }
            setSnackBarStatus({
                opened:true, 
                severity:"error", 
                message: errorMessage
            })
        });
        setLoading(false)
    }

    if (loading) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <div style={{ width: '400px', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </div>
            </Dialog>
        )
    }

    return (
        <>
            <Dialog open={open}>
                <div style={{ width: '400px', padding: '2rem' }}>
                    <IconButton
                        aria-label="Close"
                        onClick={() => {
                            setOpen(false)
                            resetForm()
                        }}
                        style={{ float: "right", width: "30px", height: "30px"}}
                    >
                    <CloseIcon />
                    </IconButton>
                    <h2 style={{ marginBottom: '1.5rem' }}>Convidar Usuário</h2>
                    <LabelInput
                        labelValue="Nome"
                        value={values.first_name}
                        onChange={(value) =>
                            handleInputChange("first_name", value)
                        }
                        onBlur={(value) => handleBlur("first_name", value)}
                        error={touched.first_name && values.first_name === ""}
                        errorMessage={errorMessages.first_name}
                    />
                    <LabelInput
                        labelValue="Sobrenome"
                        value={values.last_name}
                        onChange={(value) =>
                            handleInputChange("last_name", value)
                        }
                        onBlur={(value) => handleBlur("last_name", value)}
                        error={touched.last_name && values.last_name === ""}
                        errorMessage={errorMessages.last_name}
                    />
                    <LabelInput
                        labelValue="CPF"
                        value={values.document_number}
                        placeholder="000.000.000-00"
                        onChange={(value) =>
                            handleInputChange("document_number", value)
                        }
                        onBlur={(value) => handleBlur("document_number", value)}
                        formatValue={(value) => formatCPF(value)}
                        error={(touched.document_number && values.document_number === "") ||  (touched.document_number && !isValidCPF(values.document_number))}
                        errorMessage={errorMessages.document_number}
                    />
                    <LabelInput
                        labelValue="E-mail"
                        value={values.email}
                        onChange={(value) =>
                            handleInputChange("email", value)
                        }
                        onBlur={(value) => handleBlur("email", value)}
                        error={touched.email && !isEmail(values.email)}
                        errorMessage={errorMessages.email}
                    />
                    <h2 style={{ marginBottom: '1.0rem' }}>Administrador</h2>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={adminDashboard} onChange={() => setAdminDashboard(!adminDashboard)}></S.Input>
                        <S.PermissionDescription>Adicionar esse usuário como administrador</S.PermissionDescription>
                    </S.PermissionContainer>
                    <h2 style={{ marginBottom: '1.0rem', marginTop: '1.0rem'}}>Permissões</h2>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={createEnvelopes} onChange={() => setCreateEnvelopes(!createEnvelopes)}></S.Input>
                        <S.PermissionDescription>Criar novo envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readEnvelope} onChange={() => setReadEnvelope(!readEnvelope)}></S.Input>
                        <S.PermissionDescription>Leitura das informações do envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={cancelEnvelope} onChange={() => setCancelEnvelope(!cancelEnvelope)}></S.Input>
                        <S.PermissionDescription>Cancelar envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={listEnvelopes} onChange={() => setListEnvelopes(!listEnvelopes)}></S.Input>
                        <S.PermissionDescription>Visualizar a listagem de envelopes</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={loadEnvelopeReport} onChange={() => setLoadEnvelopeReport(!loadEnvelopeReport)}></S.Input>
                        <S.PermissionDescription>Visualizar dossiê de análise do envelope</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={createReports} onChange={() => setCreateReports(!createReports)}></S.Input>
                        <S.PermissionDescription>Criar relatório</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readReports} onChange={() => setReadReports(!readReports)}></S.Input>
                        <S.PermissionDescription>Visualizar a listagem de relatórios</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readEnvelopeReports} onChange={() => setReadEnvelopeReports(!readEnvelopeReports)}></S.Input>
                        <S.PermissionDescription>Visualizar relatórios de envelopes</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={readCompanySettings} onChange={() => setReadCompanySettings(!readCompanySettings)}></S.Input>
                        <S.PermissionDescription>Visualizar configurações</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={updateCompanySettings} onChange={() => setUpdateCompanySettings(!updateCompanySettings)}></S.Input>
                        <S.PermissionDescription>Editar configurações</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={updateSignerData} onChange={() => setUpdateSignerData(!updateSignerData)}></S.Input>
                        <S.PermissionDescription>Editar informações do assinante</S.PermissionDescription>
                    </S.PermissionContainer>
                    <S.PermissionContainer>
                        <S.Input type="checkbox" checked={renotifySigner} onChange={() => setRenotifySigner(!renotifySigner)}></S.Input>
                        <S.PermissionDescription>Realizar renotificação de assinatura</S.PermissionDescription>
                    </S.PermissionContainer>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSubmit} 
                            style={{ width: '100%', height: '32px', fontSize: '12px', margin: '2rem 0', backgroundColor: primaryColor, color:'white' }}
                        >
                            Convidar
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Snackbar
                open={snackBarStatus.opened}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackBar}
                    severity={snackBarStatus["severity"]}
                >
                    {snackBarStatus.message}
                </MuiAlert>
            </Snackbar>
        </>
    )
};

export default UserInviteDialog
