import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

export const CancelSignerBatchErrorDialog = (props) => {

    const {cancelSignerBatchError, setCancelSignerBatchError} = props

    useEffect(() => {
        let timeoutId;
        if (cancelSignerBatchError) {
            timeoutId = setTimeout(() => {
                setCancelSignerBatchError(false)
            }, 2000);
        }
        return () => {
            clearTimeout(timeoutId)

        };
    }, [cancelSignerBatchError,setCancelSignerBatchError]);

    return (
        <dialog
            className="dialog--temporary"
            open={cancelSignerBatchError}
            onClose={()=>setCancelSignerBatchError(false)}
        >
            <div className="dialog__row-container">
                <header>
                    <h2>
                    Erro ao cancelar a assinatura em lote
                    </h2>
                </header>
                <IconButton 
                    onClick={()=>setCancelSignerBatchError(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </dialog>
    );
}