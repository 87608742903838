import { CircularProgress } from "@mui/material"

export const LoadingPage = (props) => {
    
    return (
        <div className="page-container">
            <p className="message__container message__container--centered">
                <span className="message__text">
                    Loading ...
                </span>
                <CircularProgress style={{color:"#525252", width:"20px", height:"20px", margin:"10px auto"}}/>
            </p>
        </div>
    )
}