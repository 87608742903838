import React, { useEffect, useCallback, useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import * as S from "../styles";
import { LoadingPage } from "../../AuxiliarPages/LoadingPage/LoadingPage";
import { ErrorPage } from "../../AuxiliarPages/ErrorPage/ErrorPage";
import { Pagination, TableHead } from "@mui/material";
import ExportCSV from "../../../utils/ExportCSV/ExportCSV";
import {
  getfilterReportToFront,
  getfilterDataToFront,
  formatDateReports,
} from "../../../utils/utils";
import qs from "qs";

function ReportsList() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [reports, setReports] = useState(null);
  const [currentReportPage, setCurrentReportPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [config, setConfig] = useState({
    params: {
      page_number: 0,
      page_rows: 25,
    },
  });

  const handleReportPage = (event, page) => {
    if (currentReportPage === page) {
      return;
    }
    setCurrentReportPage(page);
  };

  const requestReports = useCallback(() => {
    const timer = setTimeout(() => {
      setLoading(true)
      const queryString = qs
      .stringify(config, { arrayFormat: "repeat", encode: false })
      .replaceAll("params", "")
      .replaceAll("[", "")
      .replaceAll("]", "");
    axios
      .get(`/dash/report/reports?${queryString}`)
      .then((response) => {
        setReports(response.data.data);
        setMaxPage(response.data.number_of_pages);
      })
      .catch((error) => {
        setError({
          has_error: true,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [config]);

  useEffect(() => {
    let newConfig;
    newConfig = config;
    newConfig.params.page_number = currentReportPage - 1;
    setConfig(newConfig);
    requestReports();
  }, [currentReportPage, config, requestReports]);

  if (loading) {
    return (
      <S.ReportContainer>
        <LoadingPage />
      </S.ReportContainer>
    );
  } else if (error) {
    return (
      <S.ReportContainer>
        <ErrorPage />
      </S.ReportContainer>
    );
  } else if (reports) {
    return (
      <>
        <S.ReportListContainer>
            <S.ReportTemplateDescription style={{marginTop: "30px", marginBottom: "20px"}}>
                Listagem de relatórios
            </S.ReportTemplateDescription>
          {reports.length !== 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "14px", textAlign: "left", fontWeight: 600, fontFamily: "Plus Jakarta Sans" }}>Filtros</TableCell>
                        <TableCell sx={{ padding: "0px", fontSize: "14px", textAlign: "left", fontWeight: 600, fontFamily: "Plus Jakarta Sans" }}>Título do relatório</TableCell>
                        <TableCell sx={{ fontSize: "14px", textAlign: "left", fontWeight: 600, fontFamily: "Plus Jakarta Sans" }}>Data de emissão</TableCell>
                        <TableCell sx={{ padding: "22px", fontSize: "14px", textAlign: "left", fontWeight: 600, fontFamily: "Plus Jakarta Sans" }}>Arquivo</TableCell>
                    </TableRow>
                </TableHead>
                  <TableBody>
                    {reports.map((report, index) => (
                      <ReportItem key={index} report={report} />
                    ))}
                  </TableBody>
              </Table>
            </TableContainer>
          ): (
            <S.ReportTemplateContainer>
                <S.ReportNoContentDescription>
                  Não há relatórios disponíveis
                </S.ReportNoContentDescription>
            </S.ReportTemplateContainer>
          )}
          <Pagination
            style={{ marginTop: "15px", marginBottom: "15px"}}
            page={currentReportPage}
            count={maxPage}
            onChange={(event, page) => handleReportPage(event, page)}
          />
        </S.ReportListContainer>
      </>
    );
  }
}

function ReportItem(props) {
  const { report } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={{ fontSize: "15px", textAlign: "left", padding: "0px" }}
        >
          {report.report_template}
        </TableCell>
        <TableCell style={{ fontSize: "15px", textAlign: "left" }}>
          {formatDateReports(report.report_events[0].event_date)}
        </TableCell>
        <TableCell style={{ fontSize: "15px", textAlign: "left" }}>
          <ExportCSV
            pageParams={{}}
            csv_endpoint={"".concat(
              "/dash/report/report/",
              report.report_key,
              "/file"
            )}
            file_name={report.report_template}
            is_able={report.report_status === "available" ? true : false}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <S.FilterListContainer>
              <S.FilterListHeader>Filtros aplicados</S.FilterListHeader>
              {Object.entries(report.applied_filters || {}).map(
                ([key, value], index) => (
                  value !== null &&
                  (<div
                    key={index}
                    style={{ display: "flex", marginBottom: "10px" }}
                  >
                    <S.FilterListItemDescription>
                      {getfilterReportToFront(key)}:
                    </S.FilterListItemDescription>
                    <S.FilterListItemDescription>
                      {getfilterDataToFront(key, value)}
                    </S.FilterListItemDescription>
                  </div>)
                )
              )}
            </S.FilterListContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ReportsList;
