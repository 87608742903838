import React, { useState, useEffect, useCallback } from 'react'
import { SideBar } from './containers/SideBar'
import { TopBar } from './containers/TopBar'
import { EnvelopeInbox } from "./containers/Envelope/EnvelopeInbox"
import { EnvelopePage } from "./containers/Envelope/EnvelopePage/EnvelopePage"
import { SettingsPage } from './containers/SettingsPage/SettingsPage'
import { ReportInbox } from "./containers/Report/ReportInbox"
import { ActivateInbox } from "./containers/ActivateAccount/ActivateInbox"
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from "@mui/icons-material/Email"
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from "@mui/icons-material/Settings"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import AuthContext from "./context/auth-context"
import { FailedAuthPage } from './containers/AuxiliarPages/FailedAuthPage/FailedAuthPage'
import { RedirectPage } from './containers/AuxiliarPages/RedirectPage/RedirectPage'
import axios from 'axios'
import { UserManagementPage } from './containers/UserManagementPage/UserManagementPage'
import { ContactManagementPage } from './containers/ContactManagementPage/ContactManagementPage'
import { ContactsPage } from './containers/ContactManagementPage/ContactsPage/ContactsPage'
import { SignatureAreaPage } from './containers/SignatureAreaPage/SignatureAreaPage'
import { SignatureAreaCreation } from './containers/SignatureAreaPage/SignatureAreaCreation/SignatureAreaCreation'
import PeopleIcon from '@mui/icons-material/People';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EditNoteIcon from '@mui/icons-material/EditNote';

function App(props) {

	const { keycloak } = props
	const [keycloakState, setKeycloakState] = useState({ keycloak: null, authenticated: false, user_data: null })

	const refreshToken = useCallback(() => {
		keycloak
			.updateToken(300)
			.then((refreshed) => {
				if (refreshed) {
					console.debug('Token refreshed ' + refreshed)
					axios.defaults.headers = { "Authorization": keycloak.token }
					let user_data = {
						"username": keycloak.tokenParsed.preferred_username,
						"company_key": keycloak.tokenParsed.company_key,
						"email": keycloak.tokenParsed.email,
						"name": keycloak.tokenParsed.name,
						"first_name": keycloak.tokenParsed.given_name,
						"last_name": keycloak.tokenParsed.family_name,
						"document_number": keycloak.tokenParsed.document_number,
						"roles": keycloak.tokenParsed.realm_access.roles,
						"user_key": keycloak.tokenParsed.sub,
						"locale": keycloak.tokenParsed.locale
					}
					setKeycloakState({
						keycloak: keycloak,
						authenticated: keycloak.authenticated,
						user_data
					})
				} else {
					console.warn(
						'Token not refreshed, valid for ' +
						Math.round(
							keycloak.tokenParsed.exp +
							keycloak.timeSkew -
							new Date().getTime() / 1000
						) +
						' seconds'
					)
				}
			})
			.catch(() => {
				console.error('Failed to refresh token')
			})
	}, [keycloak])

	useEffect(() => {
		const refreshInterval = setInterval(refreshToken, 270000)
		refreshToken()
		return () => clearInterval(refreshInterval)
	}, [refreshToken])

	const router = [
		{
			path: "envelope-inbox",
			element: <EnvelopeInbox />
		},
		{
			path: "report-inbox",
			element: <ReportInbox />
		},
		{
			path: "envelope/:envelopeKey",
			element: <EnvelopePage />
		},
		{
			path: "settings",
			element: <SettingsPage />
		},
		{
			path: "user-management",
			element: <UserManagementPage />
		},
		{
			path: "contact-management",
			element: <ContactManagementPage />
		},
		{
			path: "signature-area-inbox",
			element: <SignatureAreaPage />
		},
		{
			path: "signature-area-creation",
			element: <SignatureAreaCreation />
		},
		{
			path: "contact_group/:contactGroupKey/contacts",
			element: <ContactsPage />
		},
		{
			path: "activate-inbox",
			element: <ActivateInbox />
		},
		{
			path: "*",
			element: <RedirectPage />
		}
	]

	const sideBarItems = [
		{
			name: "Envelope de Documentos",
			role: "list_envelopes",
			id: "envelope-inbox",
			icon: <EmailIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Relatórios",
			role: "read_reports",
			id: "report-inbox",
			icon: <ArticleIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Configurações",
			role: "read_company_settings",
			id: "settings",
			icon: <SettingsIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Gestão de Usuários",
			role: "admin_dashboard",
			id: "user-management",
			icon: <PeopleIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Agenda de Contatos",
			role: "create_envelopes",
			id: "contact-management",
			icon: <PermContactCalendarIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Área de Assinatura",
			role: "create_signer_batch",
			id: "signature-area-inbox",
			icon: <EditNoteIcon style={{ width: "18px", marginRight: "10px" }} />
		},
		{
			name: "Ativação da conta",
			role: "selfservice_customer",
			id: "activate-inbox",
			icon: <AttachMoneyIcon style={{ width: "18px", marginRight: "10px" }} />
		}
	]

	if (!keycloakState.authenticated) {
		return (
			<div></div>
		)
	} else if (keycloakState.authenticated) {
		return (
			<AuthContext.Provider
				value={{
					keycloak_adapter: keycloakState.keycloak,
					authenticated: keycloakState.authenticated,
					user_data: keycloakState.user_data,
				}}
			>
				<Router>
					<div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
						<TopBar />
						<div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
							<SideBar sideBarItems={sideBarItems} />
							<Routes>
								{router.map((route) => (
									<Route
										key={route.path}
										path={route.path}
										element={route.element}
									/>
								))}
							</Routes>
						</div>
					</div>
				</Router>
			</AuthContext.Provider>
		)
	} else {
		return (
			<FailedAuthPage />
		)
	}
}

export default App
