import { useLocation, useNavigate } from "react-router-dom"

export const SideBarItems = (props) => {

    const { id, name } = props

    const navigate = useNavigate()
    const location = useLocation()
    const url = location.pathname
    
    const sideBarClassName = url.includes(id.split("-")[0])? "side-bar__item--selected":"side-bar__item--normal"

    return (
        <div id={id} onClick={props.onLogout?props.onLogout:(event)=>navigate(`/${event.target.id}`)} className={"side-bar__items "+sideBarClassName}>
            {props.children}
            <div id={id}>{name}</div>
        </div>
    )
}