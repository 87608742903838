import React from "react";
import ErrorBoundary from "../containers/AuxiliarPages/ErrorBoundary/ErrorBoundary";

export const ColorInputField = (props) => {

    const { label, color, setColor, active } = props

    return (
        <ErrorBoundary>
            <div className="color-field" >
                <span>{`${label}: `}</span>
                {color?
                <input 
                    className={active?"btn--animated":null} 
                    type="color" 
                    value={color} 
                    onChange={(e)=>setColor(e.target.value)} 
                    disabled={!active}
                />
                :
                <span 
                    className="color-field--gray"
                >
                    Sem cor definida
                </span>}
            </div>
        </ErrorBoundary>
    )
}