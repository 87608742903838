import React, { useState } from "react";
import axios from "axios";
import DownloadCSVErrorDialog from "./DownloadCSVErrorDialog/DownloadCSVErrorDialog";
import CSVLimitSizeDialog from "./CSVLimitSizeDialog/CSVLimitSizeDialog";
import * as S from "../../containers/Report/styles";
import moment from "moment";

function ExportCSV(props) {
  const { pageParams, csv_endpoint, file_name, is_able } = props;

  let [isCsvAble, setIsCsvAble] = useState(is_able);

  const downloadCSV = () => {
    setIsCsvAble(false);
    let csv_filters = {};

    if (pageParams.filters) {
      Object.keys(pageParams.filters).forEach((key, index) => {
        csv_filters[key] = pageParams.filters[key];
      });
    }
    axios({
      url: csv_endpoint,
      method: "GET",
      responseType: "blob",
      params: csv_filters,
    })
      .then((response) => {
        setIsCsvAble(true);

        parseInt(response.headers["csv_rows_number"]) >
        parseInt(response.headers["csv_limit_rows_number"])
          ? handleCSVLimitSizeDialogOpen()
          : handleCSVLimitSizeDialogClose();

        let datetime = moment().format("YYYY-MM-DDTHH-mm-SS");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file_name}_${datetime}.csv`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setIsCsvAble(true);
        handleDownloadCSVErrorDialogOpen();
      });
  };

  let [downloadCSVErrorDialog, setDownloadCSVErrorDialog] = useState(false);

  const handleDownloadCSVErrorDialogOpen = () => {
    setDownloadCSVErrorDialog(true);
  };

  const handleDownloadCSVErrorDialogClose = () => {
    setDownloadCSVErrorDialog(false);
  };

  let [csvLimitSizeDialog, setCSVLimitSizeDialog] = useState(false);

  const handleCSVLimitSizeDialogOpen = () => {
    setCSVLimitSizeDialog(true);
  };

  const handleCSVLimitSizeDialogClose = () => {
    setCSVLimitSizeDialog(false);
  };

  return (
    <>
      <S.CsvContainer>
        <S.CsvButtonContainer
          style={{ cursor: !isCsvAble ? "wait" : "pointer" }}
        >
          {!isCsvAble ? (
            <S.CsvButtonProcessingContainer>
				<S.CsvProcessingButtonDescription>
					Em processamento
				</S.CsvProcessingButtonDescription>

			</S.CsvButtonProcessingContainer>
          ) : (
            <S.CsvButtonExportContainer
              onClick={isCsvAble ? downloadCSV : null}
            >
              <S.CsvExportButtonDescription>
                Exportar CSV
              </S.CsvExportButtonDescription>
            </S.CsvButtonExportContainer>
          )}
        </S.CsvButtonContainer>
      </S.CsvContainer>
      <DownloadCSVErrorDialog
        open={downloadCSVErrorDialog}
        onClose={handleDownloadCSVErrorDialogClose}
      />
      <CSVLimitSizeDialog
        open={csvLimitSizeDialog}
        onClose={handleCSVLimitSizeDialogClose}
      />
    </>
  );
}

export default ExportCSV;
