import React, { useState, useEffect, useContext, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { ErrorPage } from "../../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../../AuxiliarPages/LoadingPage/LoadingPage"
import { NoAccessPage } from "../../AuxiliarPages/NoAccessPage/NoAccessPage";
import AuthContext from "../../../context/auth-context"
import * as S from '../styles'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { DataField } from "../../../components/DataField"
import ErrorBoundary from '../../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import ContactCreationDialog from "../ContactCreationDialog "
import ContactEditionDialog from '../ContactEditionDialog'
import ContactDeleteDialog from "../ContactDeleteDialog"
import { Pagination } from "../../../components/Pagination"
import BaseButton from '../../../components/BaseButton'
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { formatBirthdateToFront } from "../../../utils/utils"
import SearchBar from "../../../components/SearchBar/index.js";


export const ContactsPage = (props) => {
    
    const contactGroupKey = useParams().contactGroupKey

    const userRoles = useContext(AuthContext).user_data.roles
    const company_key = useContext(AuthContext).user_data.company_key

    const navigate = useNavigate()
    if (!userRoles.includes("create_envelopes")){
        navigate("/envelope-inbox")
    }

    const handleRefresh = () => {
        setRefreshPage(!refreshPage)
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') return
        setSnackBarStatus({...snackBarStatus, opened: false});
    };

    const [snackBarStatus, setSnackBarStatus] = React.useState({
        opened: false,
        severity: "success",
        message: ""
    });

    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })


    const handleContactEditDialog = (contact) => {
        setContact(contact)
        setOpenContactEditDialog(true)
    };

    const handleContactDeleteDialog = (contact) => {
        setContact(contact)
        setOpenContactDeleteDialog(true)
    };

    const [contact, setContact] = useState(undefined)
    const [contactsData, setContactsData] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [refreshPage, setRefreshPage] = useState(false)
    const [createContactDialog, setOpenCreateContactDialog] = useState(false);
    const [editContactDialog, setOpenContactEditDialog] = useState(false);
    const [deleteContactDialog, setOpenContactDeleteDialog] = useState(false);
    const [contactGroup, setContactGroup] = useState(undefined)

    const [filterContent, setFilterContent] = useState("");
    const [refresher, setRefresher] = useState(false);
    const [search, setSearch] = useState("");

    const handleSearch = (queryParameter) => {
        setSearch(queryParameter)
    };

    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [config, setConfig] = useState({
        params: {
            page_number: 0,
            page_rows: 4
        }
    })

    const requestContactGroup = useCallback(() => {
        const timer = setTimeout(() => {
            setLoading(true)
        
            axios.get(`/dash/sign/company/${company_key}/contact_group/${contactGroupKey}`)
                .then((response) => {
                    setContactGroup(response.data)
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 600)
        return () => {
            clearTimeout(timer)
        }
    }, [company_key, contactGroupKey])

    useEffect(() => {
        requestContactGroup()
    }, [requestContactGroup])

    const requestContacts = useCallback(() => {
        const timer = setTimeout(() => {
            setLoading(true)

            const queryString = `${search ? `${search}&` : '?'}page_number=${config.params.page_number}&page_rows=${config.params.page_rows}`;

            axios.get(`/dash/sign/company/${company_key}/contact_group/${contactGroupKey}/contacts${queryString}`)
                .then((response) => {
                    setContactsData(response.data)
                    setMaxPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 600)
        return () => {
            clearTimeout(timer)
        }
    }, [config, company_key, contactGroupKey, search])

    useEffect(() => {
        let newConfig
        newConfig = config
        newConfig.params.page_number = currentPage
        setConfig(newConfig)
        requestContacts()
    }, [currentPage, config, requestContacts, refreshPage])

    if (!userRoles.includes("create_envelopes")) {
        return (
          <S.Container>
            <NoAccessPage />
          </S.Container>
        );
    }
    
    if (loading) {
        return (
            <LoadingPage />
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else  {
        return (
            <S.Container>
                <S.SectionHeaderContainer>
                    <S.SectionTitle>
                        Agenda de Contatos 
                    </S.SectionTitle>
                    {contactGroup && (
                        <>
                            <S.SectionSubtitle>
                                Grupo de contato: {contactGroup.name}
                            </S.SectionSubtitle>
                        </>
                    )}
                    <BaseButton
                        label="Adicionar contato"
                        onClick={() => setOpenCreateContactDialog(true)}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={false}
                    />
                </S.SectionHeaderContainer>
                <S.FilterContainer>
                    <SearchBar
                        label="Pesquisar contato"
                        content={filterContent} 
                        setContent={setFilterContent} 
                        refresher={refresher} 
                        setRefresher={setRefresher}
                        onSearch={handleSearch}
                        variable={"contact_name"}
                    />
                </S.FilterContainer>
                {contactsData && (
                <ul className="inbox">
                    {contactsData.contacts.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhum contato encontrado
                        </span>
                    </p>}
                    {contactsData.contacts.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left" style={{marginLeft: "12px"}}>Contatos:</div>
                            <div className="inbox__cell--center" style={{marginLeft: "12px"}}>Dados do Contato:</div>
                            <div className="inbox__cell--center" style={{marginLeft: "60px"}}>Métodos de Envio:</div>
                            <div className="inbox__cell--right" style={{marginLeft: "224px"}}>Gerenciamento:</div>
                        </li>}
                    {contactsData.contacts.map((contact) => (
                        <li key={contact.contact_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left">{contact.contact_name}</div>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <div className="envelope__signer-cell--width inbox__cell--column-container">
                                        {contact.contact_data.name &&
                                            <DataField
                                                label="Nome completo"
                                                field={contact.contact_data.name}
                                            />}
                                        {contact.contact_data.document_number &&
                                            <DataField
                                                label="Documento"
                                                field={contact.contact_data.document_number}
                                            />}
                                        {contact.contact_data.birthdate &&
                                            <DataField
                                                label="Data de Nascimento"
                                                field={formatBirthdateToFront(contact.contact_data.birthdate)}
                                            />}
                                        {contact.contact_data.email &&
                                            <DataField
                                                label="Email"
                                                field={contact.contact_data.email}
                                            />}
                                        {contact.contact_data.phone &&
                                            <DataField
                                                label="Telefone"
                                                field={`${contact.contact_data.phone.international_dial_code} (${contact.contact_data.phone.area_code}) ${contact.contact_data.phone.number.slice(0, 5)}-${contact.contact_data.phone.number.slice(5, 9)}`}
                                            />} 
                                    </div> 
                                </div>
                                {contact.contact_data.document_submission_method &&
                                    <div className="inbox__cell--center">
                                         <div className="envelope__signer-cell--width inbox__cell--column-container">
                                            {contact.contact_data.document_number &&
                                                <DataField
                                                    label="Documentos"
                                                    field={contact.contact_data.document_submission_method}
                                                />}
                                        </div>
                                        <div className="envelope__signer-cell--width inbox__cell--column-container">
                                            {contact.contact_data.authentication_submission_method &&
                                                <DataField
                                                    label="Autenticação"
                                                    field={contact.contact_data.authentication_submission_method}
                                                />}
                                        </div> 
                                    </div>}
                                <div className="inbox__cell--right inbox__cell--row-container">
                                    <S.ManagementContainer>
                                        <button
                                            className="btn btn--animated btn--white"
                                            style={{width: "35px", height: "35px"}}
                                            onClick={() => handleContactEditDialog(contact)}
                                        >
                                            <EditNoteIcon
                                                style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                id={contact.contact_key}
                                            />
                                        </button>
                                        <S.ManagementDescription>
                                            Editar
                                        </S.ManagementDescription>
                                    </S.ManagementContainer>
                                    <S.ManagementContainer>
                                        <button
                                            className="btn btn--animated btn--white"
                                            style={{width: "38px", height: "38px"}}
                                            onClick={() => handleContactDeleteDialog(contact)}
                                        >
                                            <DeleteSweepIcon
                                                style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                id={contact.contact_key}
                                            />
                                        </button>
                                        <S.ManagementDescription>
                                            Remover
                                        </S.ManagementDescription>
                                    </S.ManagementContainer>
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>)}
                {createContactDialog &&
                    <ContactCreationDialog
                        open={createContactDialog}
                        setOpen={setOpenCreateContactDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        contactGroupKey={contactGroupKey}
                    />}
                {editContactDialog &&
                    <ContactEditionDialog
                        open={editContactDialog}
                        setOpen={setOpenContactEditDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        contactGroupKey={contactGroupKey}
                        contact={contact}
                    />}
                {deleteContactDialog &&
                    <ContactDeleteDialog
                        open={deleteContactDialog}
                        setOpen={setOpenContactDeleteDialog}
                        setSnackBarController={setSnackBarStatus}
                        handleRefresh={handleRefresh}
                        contactGroupKey={contactGroupKey}
                        contact={contact}
                    />}
                <Snackbar
                        open={snackBarStatus.opened}
                        autoHideDuration={500}
                        onClose={handleCloseSnackBar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackBar}
                        severity={snackBarStatus["severity"]}
                        style={{fontSize: '12px'}}
                    >
                        {snackBarStatus.message}
                    </MuiAlert>
                </Snackbar>
                <Pagination
                    currentPage={currentPage}
                    maxPage={maxPage}
                    setCurrentPage={setCurrentPage}
                    maxVisiblePages={7}
                />
            </S.Container>
        )
    }
}