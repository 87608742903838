//Importing modules
import React from "react";
import InputContent from "./InputContent"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Defining dialog style
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });



//Defining dialog title div
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


//Defining new card dialog component
function RegisterCardDialog(props) {
    //Getting atributes from props
    const { onClose } = props
    const { onSuccess } = props

    //Rendering dialog
    return (
        <Dialog 
        open={props.open}
        onClose={onClose}
        keepMounted={false}>
            <DialogTitle 
                className={['dialogTitle'].join(" ")} 
                onClose={onClose}
                style={{paddingBottom:"0"}}
            >
                Cadastrar Novo Cartão
            </DialogTitle>
            <DialogContent >
                <div style={{width:"500px", maxHeight: "650px", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
                  <InputContent 

                    onClose={onClose}
                    onSuccess={onSuccess}
                  />
                </div>
            </DialogContent>
        </Dialog>
    )
}


//Exporting new card dialog component
export default RegisterCardDialog