import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import Filter from "./Filter/Filter";
import {
  addFilters,
  filterChange,
  changeApply,
  filterClose,
  dateChange,
  verifyRequest,
  getSelectItens,
  getFilterInitialState
} from "./utilsFilter";

import * as S from "../../../src/containers/Report/styles"

function FilterBox(props) {
  const { setReportTemplateFilters } = props;
  let { filterTypes } = props;
  let { initialFilters } = props

  let [filterObject, setFilterObject] = useState(getFilterInitialState(filterTypes, initialFilters));

  const handleFilterAdd = (event) => {
    let new_filterObject = { ...filterObject };
    new_filterObject = addFilters(event.target.value, new_filterObject);
    setFilterObject(new_filterObject);
  };

  const handleFilterClose = (column_name) => {
    let new_filterObject = { ...filterObject };
    let values = filterClose(column_name, new_filterObject);
    new_filterObject = values[0];
    let doRequest = values[1];
    if (doRequest) {
      if (filterTypes[column_name].type === "date") {
        handleDateRequest(column_name, true);
      } else {
        handleFilterRequest(column_name);
      }
    }
    setFilterObject(new_filterObject);
  };

  /////Don't include Date Filter
  const handleFilterChange = (event, column_name) => {
    let new_filterObject = { ...filterObject };
    new_filterObject = filterChange(
      event.target.value,
      column_name,
      filterTypes[column_name],
      new_filterObject
    );
    setFilterObject(new_filterObject);
    setReportTemplateFilters({ filters: filterObject.toPass });
  };

  /////Don't include Date Filter
  const handleFilterRequest = (column_name) => {
    if (filterObject.params[column_name].error) return; //Error dont make request
    if (
      filterObject.params[column_name].value.length === 0 &&
      !filterObject.params[column_name].applied
    )
      return; //Not applied and length 0 dont make request

    let new_filterObject = { ...filterObject };
    new_filterObject = changeApply(new_filterObject);
    setFilterObject(new_filterObject);
    setReportTemplateFilters({ filters: filterObject.toPass });
  };

  /////Only for Date Filter
  const handleDateChange = (newDates, column_name) => {
    let new_filterObject = { ...filterObject };
    new_filterObject = dateChange(column_name, newDates, new_filterObject);
    setFilterObject(new_filterObject);
  };

  /////Only for Date Filter
  const handleDateRequest = (column_name, forceRequest = false) => {
    let new_filterObject = { ...filterObject };
    let values = verifyRequest(column_name, new_filterObject);
    new_filterObject = values[0];
    let doRequest = forceRequest ? true : values[1];
    if (doRequest) {
      setReportTemplateFilters({ filters: filterObject.toPass });
    }
    setFilterObject(new_filterObject);
    
  };

  const filters = filterObject.filters.map((column_name, index) => (
      <Filter
        key={column_name}
        filter_type={filterTypes[column_name]}
        filter={filterTypes[column_name]}
        value={
          filterTypes[column_name].type === "date"
            ? {
                initialDate: filterObject.params[column_name].initial_date_value,
                finalDate: filterObject.params[column_name].final_date_value,
              }
            : filterObject.params[column_name].value
        }
        onClose={() => handleFilterClose(column_name)}
        onChange={
          filterTypes[column_name].type === "date"
            ? (newDates) => handleDateChange(newDates, column_name)
            : (e) => handleFilterChange(e, column_name)
        }
        onBlur={
          filterTypes[column_name].type === "date"
            ? () => handleDateRequest(column_name)
            : () => handleFilterRequest(column_name)
        }
      />
  ));

  return (
    <>
      <S.FilterBoxContainer>
        <S.FilterBox>
            <Select
              style={{margin: "10px", width: "130px", fontSize: "13px", lineHeight: "16px"}}
              displayEmpty
              renderValue={() => "Filtrar"}
              onChange={handleFilterAdd}
              value={filterObject.select_value}
            >
              {getSelectItens(filterObject)}
            </Select>
        </S.FilterBox>
        <S.FilterContent>
          {filters}
        </S.FilterContent>
      </S.FilterBoxContainer>
    </>
  );
}

export default FilterBox;
