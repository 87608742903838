import React from "react";
import FilterBox from "../../../utils/FilterBox/FilterBox"

function Filter(props) {

    const { setReportTemplateFilters, reportFilterTypes } = props
    
    let filterTypes = reportFilterTypes.filter(filter => filter.name !== 'final_date' && filter.name !=='csv_rows_limit')
    
    let filterObject = {}

    filterTypes.map(filter => {
        if(filter.name === 'initial_date'){
            filterObject['date'] = {
                type: filter.type,
                disabled: false,
                validation: false, 
                description: filter.description
            }
        }
        else if(filter.name === 'document_number'){
            filterObject[filter.name] = {
                type: filter.type,
                maskType: filter.maskType,
                disabled: false,
                validation: true, 
                validation_rule: {type: "equal", value: filter.maskType.length},
                description: filter.description
            }
        } else if (filter.type === 'enum'){
            let enumList = filter.values.map(value => ([value, value]))

            filterObject[filter.name] = {
                type: filter.type,
                disabled: false,
                enumList: enumList,
                validation: false, 
                description: filter.description
            }
        } else {
            filterObject[filter.name] = {
                type: filter.type,
                disabled: false,
                validation: false, 
                description: filter.description
            }
        }
        return filter
    })

    return (
        <FilterBox 
            setReportTemplateFilters={setReportTemplateFilters}
            filterTypes={filterObject}
        />
    )

}

export default Filter