import qs from 'qs'
import React, { useState, useEffect, useCallback, useContext } from "react"
import StatusIconText from "../../components/StatusIconText"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { LoadingPage } from "../AuxiliarPages/LoadingPage/LoadingPage"
import { ErrorPage } from "../AuxiliarPages/ErrorPage/ErrorPage"
import { NoAccessPage } from "../AuxiliarPages/NoAccessPage/NoAccessPage"
import { Pagination } from "../../components/Pagination"
import { DatetimeBlock } from "../../components/DatetimeBlock"
import { DataField } from "../../components/DataField"
import { FilterGroup } from "./FilterGroup/FilterGroup"
import { formatDateToISOZulu } from "../../utils/utils"
import AuthContext from "../../context/auth-context"
import { frontEnvelopeStatusToBack } from "../../utils/utils"
import ErrorBoundary from '../AuxiliarPages/ErrorBoundary/ErrorBoundary'
import BaseButton from '../../components/BaseButton'
import EnvelopeCreationDialog from './EnvelopeCreationDialog/index.js'
import { ToastContainer, toast } from 'react-toastify'

export const EnvelopeInbox = (props) => {

    const userRoles = useContext(AuthContext).user_data.roles
    const navigate = useNavigate()

    const sendToEnvelopePage = (event) => {
        let envelopeKey = event.target.id
        if (envelopeKey) {
            navigate(`/envelope/${envelopeKey}`)
        }
    }

    const openEnvelopeCreation = () => {
        setOpenEnvelopeCreationDialog(true)
    }

    const handleToast = (message, type) => {
        if (type === "success") {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        } else if (type === "error") {
            toast.error('Ocorreu um erro ao criar o envelope. Tente novamente.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }



    const [fetchedData, setFetchedData] = useState(undefined)
    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)
    const [filterLoading, setFilterLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const initialFilterState = {
        name: "",
        document_number: "",
        external_id: "",
        status: "",
        initial_date: "",
        final_date: "",
        subject: ""
    }
    const [filters, setFilters] = useState(initialFilterState)
    const [config, setConfig] = useState({
        params: {
            page_number: 0,
            page_rows: 7
        }
    })

    const [openEnvelopeCreationDialog, setOpenEnvelopeCreationDialog] = useState(false)

    const requestEnvelopes = useCallback(() => {
        const timer = setTimeout(() => {
            setFilterLoading(true)
            const queryString = qs.stringify(config, { arrayFormat: 'repeat', encode: false }).replaceAll("params", "").replaceAll("[", "").replaceAll("]", "")
            axios.get(`/dash/sign/envelopes?${queryString}`)
                .then((response) => {
                    setFetchedData(response.data)
                    setMaxPage(response.data.pagination.number_of_pages)
                })
                .catch((error) => {
                    if ([404].includes((error.response || {}).status)) {
                        setError({
                            "has_error": true,
                            "status": error.response.status
                        })
                    } else {
                        setError({
                            "has_error": true,
                            "status": 500
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                    setFilterLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [config])

    useEffect(() => {
        let newConfig
        newConfig = config
        newConfig.params.page_number = currentPage
        setConfig(newConfig)
        requestEnvelopes()
    }, [currentPage, config, requestEnvelopes])

    const onFilter = useCallback(() => {
        setCurrentPage(0)
        let newConfig = {
            params: {
                page_number: 0,
                page_rows: 7
            }
        }
        Object.keys(filters).forEach((filter_key) => {
            if (filters[filter_key]) {
                if (filter_key === "document_number") {
                    newConfig.params[filter_key] = filters[filter_key].replaceAll(".", "").replaceAll("-", "").replaceAll(" ", "")
                } else if (filter_key === "name" || filter_key === "subject") {
                    newConfig.params[filter_key] = filters[filter_key].replaceAll(" ", "_").replaceAll(" ", "").toLowerCase()
                } else if (filter_key.includes("date")) {
                    newConfig.params[filter_key] = formatDateToISOZulu(filters[filter_key], filter_key)
                } else if (filter_key === "status") {
                    newConfig.params[filter_key] = frontEnvelopeStatusToBack[filters[filter_key]].backStatus
                } else {
                    newConfig.params[filter_key] = filters[filter_key]
                }
            }
        })
        setConfig(newConfig)
    }, [filters])

    if (!userRoles.includes("list_envelopes")) {
        return (
            <NoAccessPage />
        )
    }
    if (loading) {
        return (
            <div className="page-container">
                <FilterGroup filters={filters} setFilters={setFilters} onFilter={onFilter} filterLoading={filterLoading} />
                <header>
                    <h1>
                        Listagem de Envelopes
                    </h1>
                </header>
                <LoadingPage />
            </div>
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else if (fetchedData) {
        return (
            <div className="page-container">
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <FilterGroup filters={filters} setFilters={setFilters} onFilter={onFilter} filterLoading={filterLoading} />
                <header>
                    <h1>
                        Listagem de Envelopes
                    </h1>
                </header>
                {userRoles.includes("create_envelopes") &&
                    <BaseButton
                        label="Criar envelope"
                        onClick={() => openEnvelopeCreation()}
                        className="btn__container--center btn btn--animated btn--white"
                        isDisabled={false}
                    />}
                {fetchedData.envelopes.length === 0 &&
                    <p className="message__container message__container--centered">
                        <span className="message__text">
                            Nenhum envelope
                        </span>
                    </p>}
                <ul className="inbox">
                    {fetchedData.envelopes.length !== 0 &&
                        <li className="inbox__header">
                            <div className="inbox__cell--left">Criado em:</div>
                            <div className="inbox__cell--center">Assinantes:</div>
                            <div className="inbox__cell--right">Status:</div>
                        </li>}
                    {fetchedData.envelopes.map((envelope) => (
                        <li key={envelope.envelope_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    <DatetimeBlock dateString={envelope.created_at} />
                                    <div>
                                        <DataField
                                            label="Nome do Envelope"
                                            field={envelope.subject}
                                        />
                                        <DataField
                                            label="ID do Envelope"
                                            field={envelope.id}
                                        />
                                    </div>
                                </div>
                                <div className="inbox__cell--center">
                                    {envelope.signers.map((signer, index) => (
                                        <p
                                            className="inbox__cell--normal-text"
                                            key={`signer-${index}-${signer.document_number}`}
                                        >
                                            {`${signer.name} ${signer?.document_number ? ` - ${signer.document_number}` : ""}`}
                                        </p>
                                    ))}
                                </div>
                                <div className="inbox__cell--right inbox__cell--row-container">
                                    <StatusIconText
                                        id={envelope.envelope_key}
                                        status={envelope.status}
                                        type="envelope_status"
                                    />
                                    {userRoles.includes("read_envelope") &&
                                        <button
                                            className="btn btn--animated btn--white"
                                            id={envelope.envelope_key}
                                            onClick={(event) => sendToEnvelopePage(event)}
                                        >
                                            <ArrowRightIcon
                                                style={{ scale: "1.2", height: "1.5rem", width: "1.8rem" }}
                                                id={envelope.envelope_key}
                                            />
                                        </button>}
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                <ErrorBoundary>
                    <Pagination
                        currentPage={currentPage}
                        maxPage={maxPage}
                        setCurrentPage={setCurrentPage}
                        maxVisiblePages={7}
                    />
                </ErrorBoundary>
                {openEnvelopeCreationDialog &&
                    <EnvelopeCreationDialog
                        open={openEnvelopeCreationDialog}
                        setOpen={setOpenEnvelopeCreationDialog}
                        handleToast={handleToast}
                    />}
            </div>
        )
    }
}