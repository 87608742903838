import React, { useMemo } from "react";

export const Pagination = (props) => {

    const { currentPage, maxPage, setCurrentPage, maxVisiblePages } = props;

    const pages = useMemo(() => {
        const totalPages = maxPage || 1;
        const visiblePages = Math.min(totalPages, maxVisiblePages);
        const middlePage = Math.ceil(visiblePages / 2);
        let startPage = currentPage - middlePage + 1;
        if (startPage < 0) startPage = 0;
        if (startPage + visiblePages > totalPages) {
            startPage = totalPages - visiblePages;
        }
        return Array.from({ length: visiblePages }, (_, i) => i + startPage);
    }, [currentPage, maxPage, maxVisiblePages]);

    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage+1 === maxPage;

    const pageText = `Página ${currentPage + 1} de ${maxPage}`;

    return (
        <div className="pagination">
            <div>
                <button  onClick={() => setCurrentPage(currentPage - 1)} disabled={isFirstPage}>
                    Prev
                </button>
                {pages.map((page) => (
                    <button
                        id={currentPage === page? "selected": page+1}
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        style={{width:"50px"}}
                    >
                        {page + 1}
                    </button>
                ))}
                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={isLastPage}>
                    Next
                </button>
            </div>
            <p>{pageText}</p>
        </div>
    );
};