import React from "react";

export const FilterOption = (props) => {
    
    const { id, labelValue, frontEnvelopeStatus, filters, setFilters } = props

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: value
        });
    }

    return (
        <div className="filter">
            <label htmlFor={id}>{labelValue}:</label>
            <select
                name={id}
                value={filters[id]}
                onChange={handleInputChange}
            >
                <option value="">{`Todos os ${id}`}</option>
                {Object.keys(frontEnvelopeStatus).map((status) => (
                    <option key={status} value={status}>
                        {frontEnvelopeStatus[status].text}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default FilterOption;