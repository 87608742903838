import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import Keycloak from "keycloak-js"

axios.defaults.baseURL = process.env.REACT_APP_DASH_API_URL;
const keycloak = Keycloak("/keycloak.json")

keycloak
	.init({ onLoad: 'login-required', KeycloakResponseType: 'code' })
	.then((auth) => {
		if (!auth) {
			window.location.reload();
		} else {
			axios.defaults.headers = { "Authorization": keycloak.token }
			const root = ReactDOM.createRoot(document.getElementById('root'));
			root.render(
				<React.StrictMode>
					<App keycloak={keycloak} />
				</React.StrictMode>
			);
		}
	})
	.catch(() => {
		console.error("Authenticated Failed");
	});



reportWebVitals();
