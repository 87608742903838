import React from "react";
import ErrorBoundary from "../containers/AuxiliarPages/ErrorBoundary/ErrorBoundary";

export const WebhookInputField = (props) => {
    const { label, active, webhookUrl, setWebhookUrl } = props;

    return (
        <ErrorBoundary>
            <div className="data-field" style={{marginTop: "15px", display: "flex"}}>
                <span style={{marginRight: "10px"}}>{`${label}: `}</span>
                {webhookUrl ?
                    <input 
                        className={active ? "btn--animated" : null} 
                        type="text" 
                        value={webhookUrl} 
                        onChange={(e) => setWebhookUrl(e.target.value)} 
                        disabled={!active}
                        style={{ flex: 1, width: "300px" }}
                    />
                    :
                    <span className="color-field--gray">
                        Não há endereço cadastrado
                    </span>
                }
            </div>
        </ErrorBoundary>
    );
};
